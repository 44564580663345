@import '../../../Utils/Styles/colors.scss';

.parametersModelsVersions {
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  .parametersModelsVersions-addModelButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;

    > p {
      margin-left: 8px;
      text-transform: none;
      color: $textBlackColor;
      font-size: 12px;
      line-height: 18px;
    }

    > svg {
      fill: $textBlackColor;
      width: 16px;
      height: 16px;
    }
  }

  .parametersModelsVersions-list {
    overflow-y: auto;
    height: 100%;
    margin: 24px;
    background-color: white;
    display: flex;
    flex-direction: column;

    .list-content {
      grid-template-columns: 1fr 5fr 3fr 5fr 3fr 10fr;
      padding-top: 0;
      margin: 0;

      .header-item {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 48px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
      }
    }
  }
}
