.list-content{
  border: none;
  margin-top: 24px;
  background-color: #FFFFFF;
  padding: 32px 0;

  width: fit-content;
  min-width: 100%;

  display: grid;

  > div {
    margin: unset;
    padding: 12px 8px;
    border-bottom: 1px solid #e5eaee;
  }

  .estDelivery {
    align-items: center;
  }

  .lateDaysDiv {
    display: flex;
    align-items: center;
    margin: unset;
  }

  .numberRequests {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .deliveryLimit {
    align-items: center;
    #calendar-icon {
      margin: unset;
      margin-right: 5px;
    }

    #limitDeliveryDate {
      margin-top: 0;
    }
  }
}

.list-skeleton-wrapper {
  margin-top: 24px;
  background-color: #FFFFFF;
  padding: 32px;
  width: 100%;
}
