.blockPendencyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .blockPendency {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    .blockPendency-title {
      color: #8A9DB2;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
    }

    .blockPendency-date {
        color: #8A9DB2;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        margin-top: 4px;
      }
    }
  }
