.phoneImage{
  height: 20px;
  width: 20px;
  margin-right: 20px;
}
.phoneContact{
  display: flex;
  color: #758CA5;
  align-content: flex-end;
  align-items: center;
  margin-left: auto;
  font-size: 14px;
  white-space: pre-wrap;
}

.phoneText {
  display: flex;
  flex-direction: column;
}

.phoneSalesCar {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;

  a {
    min-width: 108px;
  }
}
