.dashboard-kpi-graph {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  width: 480px;
  height: 100%;
  align-self: center;
  position: relative;
}

.donut-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 34%;
  left: 8%;
  width: 36%;
  justify-content: center;
  height: 37%;
}

.donut-inner h5 {
  margin-bottom: 5px;
  margin-top: 0;
  font-weight: bold;
  color: #5E5E5E;
  font-size: 42px;
  line-height: 50px;
}

.donut-inner span {
  color: #5E5E5E;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
}
