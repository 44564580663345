.clientName {
  height: 16px;
  color: #5E5E5E;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  font-family: 'Helvetica Neue', sans-serif;
}
