.labelWithIconDiv {
  display: flex;
  flex-direction: row;

  .labelIcon {
    font-size: 10px;
  }

  .helpIcon-div {
    margin-left: 8px;
  }
}
