@import '../../../Utils/Styles/colors.scss';

.invoiceIcon {
  display: flex;
  justify-items: center;

  > svg {
    fill: $textSecondaryColor;
  }

  .font-small2 {
    margin-left: 8px;
    color: $textSecondaryColor;
    font-weight: bold;
    line-height: 15px;
  }
}
