.snackBarExport{
  width: 100%;
  .snackBarExport-modal{
    background-color:white;
    color: black;
  }
  .snackBarExport-button{
    font-size: 10px;
  }

  .snackBarExport-message{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .snackBarExport-icon{
      margin-right: 10px;
    }
  }
}
