.orderDetail {
  margin: 24px;
  min-width: 1248px;

  &.pda-center{
    display: flex;
    justify-content: center;
    flex-direction: column;

    margin: 0 auto;
    margin-top: 24px;
  }

  &--header{
    width: 1500px;
    height: 140px;
    background-color: #fff;

    margin-bottom: 18px;
  }
  &--timeline{
    width: 1500px;
    height: 400px;
    background-color: #fff;
  }

  .timelineCard {
    padding-bottom: 1px !important;

    &.logistic {
      padding-top: 0px !important;
    }

    & h1 {
      font-size: 32px;
      font-family: "Helvetica Neue", sans-serif;
      color: #acbac9;
      font-weight: 500;
      line-height: 0px;
    }

    &.timeline-card {
      margin: 0px;
    }
  }

  .orderDetail-returnButton {
    width: fit-content;
    margin-bottom: 16px;
    cursor: pointer;

    .orderDetail-returnButton-text {
      outline: none;
      color: #5e5e5e;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-decoration: none;

      svg {
        margin-right: 12px;
      }
    }
  }

  .orderDetail-test {
    display: flex;
    margin-top: 20px;
    height: 80px;
    justify-content: center;
    align-items: center;
  }

  .resultResponse {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .orderInformationCard-wrapper {
    margin-bottom: 18px;
  }

  .industrialOrderList .tabs-selector {
    margin-top: 0px;
    margin-bottom: -8px;
  }
}
