@import '../../../Utils/Styles/colors.scss';

.timelineCard {
  min-width: 1200px;
  max-width: 1500px;
  margin: auto;
  background-color: $actionSecondaryColor;
  padding: 40px;
  border-radius: 4px;

  .timelineCard-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0px 24px;

    .statusDetails {
      margin: 0;
      padding: 0 16px;
      width: fit-content;

      .alert-icon {
        padding: 0;
      }
      .texts {
        padding-left: 24px;
      }
    }

    .timelineCard-header-right {
      align-self: flex-start;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .orderInformationItem {
        margin-right: 40px;
      }

      .phoneContact {
        margin: 0;
      }
    }
  }

}

.timelineCard-reprogrammingCard {
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 390px;
  height: 90px;
  background: #ECEEF2;
  border-radius: 4px;

  .timelineCard-reprogrammingCard-div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  .timelineCard-reprogrammingCard-title{
    color: #89898A;
  }

  .timelineCard-reprogrammingCard-text {
      font-weight: 300;
      font-style: italic;
  }

  .timelineCard-reprogrammingCard-alertIcon {
    width: 30px;
    height: 30px;
    fill: #89898A;
  }
}


.timelineCard-skeleton {
  background-color: $actionSecondaryColor;
  width: 100%;
  height: 350px;
  margin: auto;
  min-width: 1200px;
  max-width: 1500px;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pedidoIndustrial-downloadInvoiceDiv {
  margin-top: 24px;
  background-color: white;
  padding: 24px 40px;
  border-radius: 4px;
}

.timelineCard-downloadInvoiceDiv {
  margin-top: 24px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 24px 40px;

  >svg {
    fill: #026341;
  }

  >span {
    margin-left: 16px;
    color: #026341;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;

.timelineCard-invoiceIconBlocked {
  .invoiceIcon {
    > svg {
      fill: rgba($color: $textSecondaryColor, $alpha: 0.6);
    }

    .font-small2 {
      color: rgba($color: $textSecondaryColor, $alpha: 0.6);
    }
  }
}
}
}
