@import "../../../Utils/Styles/colors.scss";

.sortingArrowIcon {
  transform: rotate(90deg);
  fill: #BEBEBE;
  cursor: pointer;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sortingArrowIcon.true {
  fill: $actionPrimaryColor;
}
