@import "../../../Utils/Styles/colors.scss";

.popup-menu {
  display: block;
  text-align: center;
  box-sizing: border-box;
  width: 111px;
  border: 1px solid rgba(117, 140, 165, 0.5);
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);

  > div:last-child {
    border-bottom: hidden;
  }

  a {
    text-decoration: none;
  }
}

.menuItem {
  height: 56px;
  width: 56px;
  display: inline-flex;
  cursor: pointer !important;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  > .menuItemIcon {
    margin: auto;
    > svg {
      fill: $stellantisBlue;
    }
  }
}

.pop-up-menu-item-div {
  border-bottom: 2px solid #e5eaee;
  &:hover {
    cursor: pointer;
    background-color: rgba(93, 127, 200, 0.1);
  }

  .pop-up-menu-item-label {
    padding: 12px 12px;
    color: #212b36;
    line-height: 20px;
  }
}
