@import "../../../Utils/Styles/colors.scss";

#helpButton-popover {
  margin-left: 12px;
}

.helpButton__popover {
  z-index: 5;
}

a.helpButton__faqLink {
  display: block;
  text-decoration: none;
  color: inherit;
  outline: none;
  cursor: pointer;

  :link :visited :active :hover {
    text-decoration: none;
    color: inherit;
    cursor: auto;
  }
}


.helpButton {
  background-color: unset;
  border: unset;
  outline: unset;
}

.helpButton-icon {
  margin: auto;
  fill: $stellantisBlue;
}

