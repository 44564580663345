.labelWithIconDiv {
  display: flex;
  flex-direction: row;
  align-items: center;

  .labelIcon {
    font-size: 10px;
  }

  .sortingIcon {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    text-align: center;
  }
}
