@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.editTemplate-modal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .editTemplate-header{
      padding: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .editTemplate-closeIcon{
        cursor: pointer;
      }
    }

    .editTemplate-buttons{
      display: flex;
      justify-content: center;
      border-top: 1px solid $backgroundPrimaryColor;
      padding: 24px;
      >button{
        width: 82px;
        height: 32px;
      }
      >button~button{
        margin-left: 16px;
      }
    }
  }


