@import "../../../Utils/Styles/colors.scss";

.delayNotifications-item {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-top: 1px solid $backgroundPrimaryColor;
  cursor: pointer;

  // remove button specs
  border-bottom: unset;
  border-left: unset;
  border-right: unset;
  outline: unset;
  background-color: unset;
  text-align: unset;

  &:hover {
    background: $blueHover;
  }

  .delayNotifications-item-content {
    display: flex;
    flex-direction: column;

    > .title {
      color: $textBlackColor;
    }

    > .date {
      color: $textBlackColor;
      margin-top: 8px;
    }
  }
}

.delayNotifications-item.isNew {
  background: rgba($color: $blueHover, $alpha: 0.5);

  &:hover {
    background: $blueHover;
  }
}
