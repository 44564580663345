@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.subMenu {
  background-color: $backgroundSecondaryColor;
  display: flex;
  flex-direction: column;

  p {
    @extend .font-body1;
  }

  .subMenu-title {
    display: flex;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid #e5eaee;

    .subMenu-titleContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 24px;
      width: 100%;

      .subMenu-titleText {
        color: $stellantisBlue;
        font-weight: 500;
      }
    }
  }

  .subMenu-icon {
    display: flex;
    align-items: center;
    margin-right: 18px;
    height: 26px;

    svg {
      fill: $stellantisBlue;
      height: 18px;
      width: 18px;
    }
  }

  .subMenu-options {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;

    .subMenu-singleOption {
      display: flex;
      border: none;
      align-items: center;
      background-color: $backgroundSecondaryColor;
      border-radius: 4px;
      height: 38px;
      cursor: pointer;
      padding: 8px;

      .subMenu-icon {
        margin-right: 16px;
        height: 18px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      p {
        color: $stellantisBlue;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
      }
    }

    .subMenu-singleOption:hover {
      background-color: #f5f8ff;
    }

    .subMenu-singleOption + .subMenu-singleOption {
      margin-top: 4px;
    }

    .selected {
      background-color: $stellantisBlue;

      .subMenu-icon {
        svg {
          fill: $backgroundSecondaryColor;
        }
      }

      p {
        color: $backgroundSecondaryColor;
      }
    }

    .disabled {
      p {
        color: #c5c7d1;
      }
      cursor: default;
    }

    .selected:hover {
      background-color: #162664;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .subMenu {
    .subMenu-options {
      flex-direction: row;

      .subMenu-singleOption + .subMenu-singleOption {
        margin-left: 4px;
        margin-top: 0px;
      }
    }
  }
}
