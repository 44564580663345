@import '../../../Utils/Styles/colors.scss';

.tagOrderStatus {
  margin: 0px;
  padding: 6px 4px 6px 4px;
  text-align: center;
  line-height: normal;
  width: max-content;
  height: 24px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

.tagOrderStatus.late {
  background-color: rgba($errorColor, 0.15);
  color: $errorColor;
}

.tagOrderStatus.onTime {
  background-color: rgba($textSecondaryColor, 0.15);
  color: $textSecondaryColor;
}

.tagOrderStatus.inRisk {
  background-color: rgba($warningColor, 0.15);
  color: $warningColor;
}

.tagOrderStatus.delivered {
  background-color: rgba($successColor, 0.15);
  color: $successColor;
}

.tagOrderStatus.withoutOrder {
  background-color: rgba($textSecondaryColor, 0.15);
  color: $textSecondaryColor;
}

.tagOrderStatus.ongoing {
  @extend .onTime;
}
