@import "../../../Utils/Styles/colors.scss";

#notification-popper {
  margin-left: 24px !important;
  width: 20%;
  min-width: 312px;
  max-width: 400px;
  border-radius: 4px;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.05),
    0px 8px 10px 1px rgba(0, 0, 0, 0.05),
    0px 3px 14px 2px rgba(0, 0, 0, 0.05);
  z-index: 6;
}

.notifications-section {
  border-radius: 4px;
  background-color: #fff;

  .notifications-header {
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .notifications-content {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
  }
}
