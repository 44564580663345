@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.downloadButton{
  height: 34px;
  width: 112px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid $textSecondaryColor;
  background-color: $backgroundSecondaryColor;

  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .downloadButton-arrow {
    transform: rotate(180deg);
  }

  .downloadButton-title {
    @extend .font-button;

    color: $textBlackColor;
    margin-left: 8px;
  }
}

