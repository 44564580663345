@import '../../../Utils/Styles/colors.scss';

.orderInformationCard-wrapper {
  margin: 24px auto;
  min-width: 1200px;
  max-width: 1500px;
}

.orderInformationCard-skeleton {
  width: 100%;
  margin: 24px auto 18px auto;
  min-width: 1200px;
  max-width: 1500px;
  height: 140px;
  border-radius: 4px;
  background-color: $actionSecondaryColor;
}

.orderInformationCard-accordionExpansionButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60px;

  > .plusSign, > .minusSign {
    fill: $actionPrimaryColor;
    margin-bottom: 8px;
  }

  > .font-small2 {
    color: $actionPrimaryColor;
  }

  & > .seeMore, & > .plusSign {
    display: block;
  }
  .Mui-expanded & > .seeMore, .Mui-expanded & > .plusSign {
    display: none;
  }
  & > .hide, & > .minusSign {
    display: none;
  }
  .Mui-expanded & > .hide, .Mui-expanded & > .minusSign {
    display: block;
  }
}
