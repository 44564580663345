@import "../../../Utils/Styles/colors.scss";

@media only screen and (max-width: 812px) {
  .timeline-client {
    .timeline-grid {

      .step-line {
        width: unset;
        z-index: 0;

        .line {
          width: 40px;
          transform: translate(-50%, -75%);
        }
      }
      .step-name {
        text-align: left;
        padding-top: 0px;
        width: 80px;

        display: flex;
        flex-direction: row;
        align-items: center;

        .chassiInfoIcon {
          margin-top: 8px;
        }
      }

      .twoDates {
        white-space: nowrap;
        padding-top: 4px;
      }

      .stepDate-status {
        white-space: pre-wrap;
        text-align: center;
        padding: 0;
        padding-top: 4px;
      }

      .step-circle {
        z-index: unset;
        position: relative;
        line-height: 0;
      }

      .step-circle-background {
        background: #FFFFFF;
        border-radius: 50%;
        align-self: center;
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translate(0%, -100%);
      }

      .step-status-circle {
        z-index: 2;
        position: relative;
      }

      .step-status-circle-progress {
        z-index: 2;
        position: relative;
      }

      .step-status-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
