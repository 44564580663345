@import '../../../Utils/Styles/colors.scss';

.notificationMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  border-top: 0.5px solid rgba($color: black, $alpha: 0.1);

  .notificationMessage-body {
    .notificationMessage-title {
      color: $textBlackColor;
    }

    .notificationMessage-date {
      margin-top: 4px;
    }
  }
}
