@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.accordion-details {
  display: flex;
  flex-direction: column;  
  padding: 0px;
  p {    
    @extend .font-small2;
  }  
}