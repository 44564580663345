.labelFilter {
  display: inline-flex;
  margin-left: 12px;
  align-items: center;

  > div {
    margin-right: 16px;
  }

  .modelCode {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    span {
      height: 12px;
      color: rgb(117, 140, 165);
      font-size: 12px;
      line-height: 12px;
      margin-right: 4px;
    }
    .modelCodeNumberSpan{
      font-weight: 500;
    }
  }
}

.labelFilter > span {
  margin: auto 0px auto 0px;
  font-weight: normal;
  font-size: 14px;
  color: #5E5E5E;
  text-transform: capitalize;
}

.status-text{
  color: inherit;
  margin: 0px;
  padding: 6px 2px 6px 2px;
  text-align: center;
  line-height: normal;
  font-weight: 500;
}

.filter-label-late {
  height: 24px;
  padding: 0px 8px;
  border-radius: 4px;
  background-color: rgba(#FD003E, 0.15);
  color: #FD003E;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

.filter-label-categories {
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 8px;
    margin: auto 4px auto 4px;
  }
}

.filter-label-onTime {
  height: 24px;
  padding: 0px 8px;
  border-radius: 4px;
  background-color: rgba(#758CA5, 0.15);
  color: #758CA5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

.filter-label-ongoing {
  @extend .filter-label-onTime;
}

.filter-label-inRisk{
  height: 24px;
  padding: 0px 8px;
  border-radius: 4px;
  background-color: rgba(#DD9304, 0.15);
  color: #DD9304;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

.filter-label-delivered{
  height: 24px;
  padding: 0px 8px;
  border-radius: 4px;
  background-color: rgba(#50E3C2, 0.15);
  color: #50E3C2;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

