$backgroundPrimaryColor: #EBEDF2;
$backgroundSecondaryColor: #FFFFFF;

$textPrimaryColor: #5E5E5E;
$textSecondaryColor: #758CA5;
$textBlackColor: #0C1C2D;
$textInactiveColor: #C5C7D1;
$placeholderColor: #CACCCF;

$inactivePrimaryStateColor: #A0A4A8;
$borderAndDividerColor: #E8E8E8;
$disabledBackgroundColor: #ECEEF2;
$blueHover: #F5F8FF;

$actionPrimaryColor: #657DD9;
$actionSecondaryColor: #FFFFFF;
$stellantisBlue: #243782;
$successColor: #50E3C2;
$warningColor: #DD9304;
$errorColor: #FD003E;
