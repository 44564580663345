.brand-logo {
  height: 30px;
  width: auto;
  max-width: 150px;
  margin: auto 0;
  &.like-square {
    height: 45px;
    margin-top: -7px;
  }
}
