.invoiceDownloadModal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  outline: none;
  border-radius: 5px;
  padding: 24px;

  width: 500px;
  height: 350px;

  .invoiceDownloadModal-juridico {
    line-height: 18px;
    white-space: pre-wrap;
    font-size: 14px;
    color: #5E5E5E;
  }

  .invoiceDownloadModal-checkboxSection {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    .test { margin-left: 0;}
    .colorSecondary {
      color: #657DD9;
    }

    .invoiceDownloadModal-termsagree {
      color: #5E5E5E;
      font-size: 15px;
      letter-spacing: 0;
    }
  }

  .invoiceDownloadModal-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;

    >span {
      white-space: pre-wrap;
      text-align: center;
      color: #5E5E5E;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 30px;
    }

    >h1 {
      color: #5E5E5E;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      font-family: inherit;
      text-align: center;
    }

    .invoiceDownloadModal-closeModalButton {
      height: 32px;
      width: 120px;
      border-radius: 3px;
      font-size: 14px;
      color: #5E5E5E;
      font-weight: 500;
      text-align: center;
      text-transform: unset;
      margin-top: 24px;
    }
  }

  .invoiceDownloadModal-startButton {
    height: 32px;
    width: fit-content;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    text-transform: unset;
  }

  .enabled {
    cursor: pointer;
    background-color: #657DD9;
    &:hover {
      background-color: #657DD9;
    }
  }

  .disabled {
    cursor: default;
    background-color: grey;
    &:hover {
      background-color: grey;
    }
  }

  .retry {
    margin-top: 12px;
    width: 150px;
  }
}

.invoiceDownloadModal-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.invoiceDownloadModalEmployee {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: static;
  width: 393px;
  height: 65px;
  left: 0px;
  top: 311px;
  background: #F5F8FF;
  border-radius: 4px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 40px;
  margin-top: 50px;
  margin-left: 0px;
}

@media only screen and (max-width: 812px) {
  .invoiceDownloadModal-wrapper {
    width: 90%;
    max-width: 500px;
    height: 50%;
    min-height: 430px;
    max-height: 500px;
  }
}
