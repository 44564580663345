.alertComponent__container {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  width: 285px;
  height: fit-content;
  padding: 16px;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 4px;

  .alertIcon {
    width: 18px;
    margin: 0;
  }

  &.error {
    border-top: 5px solid #FD003E;
    .alertIcon path {
      fill: #FD003E;
    }
  }
  &.warn {
    border-top: 5px solid #DD9304;
    .alertIcon path {
      fill: #DD9304;
    }

  }
  &.info {
    border-top: 5px solid #657DD9;
    .alertIcon path {
      fill: #657DD9;
    }
  }

  .closeIcon {
    cursor: pointer;
    width: 8px;
    height: 8px;
    margin-left: auto;
    g {
      fill: rgba(0, 0, 0, 0.54);
    }
  }

  .alertComponent__message {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 80%;
    padding-left: 16px;
    font-style: normal;
    font-weight: normal;

    .alertTitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
    }
    .alertText {
      color: #5E5E5E;
      padding-top: 4px;
      font-size: 10px;
      line-height: 14px;
    }
  }
}
