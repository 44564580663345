@import "../../../Utils/Styles/fonts.scss";

.keyBoardDatePicker {
  width: 210px;
  height: 45px;
  margin-top: 16px;

  .MuiInputBase-root {
    > input {
      @extend .font-body2;
    }
    .MuiInputAdornment-root {
      > button {
        > span {
          .MuiSvgIcon-root {
            font-size: 18px;
          }
        }
      }
    }
  }
}
