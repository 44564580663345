@import "../../../Utils/Styles/colors.scss";

.delayManagementList {
  margin-right: 24px;

  .delayManagementList-list {
    background-color: white;
    width: 100%;
    height: calc(100% - 28px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    > button {
      margin: 16px auto;
    }

    .delayManagementList-filters {
      display: flex;
      flex-direction: row;
      padding: 16px 32px;
      width: 100%;
      min-width: 800px;

      > div {
        div ~ div {
          margin-left: 24px;
        }
      }

      .searchInput {
        .search-component {
          .MuiFormControl-root {
            width: 406px;
          }
        }
      }
    }
  }

  .tabs-selector {
    margin-top: 0;
    margin-bottom: 9px;
  }

  .totalVisibleOrdersDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .totalVisibleOrdersSpan {
    margin: auto;
    color: #758ca5;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.delayManagementList-emptyState {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  > :first-child {
    margin-bottom: 16px;
  }

  h5 {
    color: $textPrimaryColor;
  }
}
