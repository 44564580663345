.sectionTitle {
	color: #5E5E5E;
	font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
}

.sectionHeader {
  display: inline-flex;
}

.filterOption {
  margin-left: 16px;
}

.searchBoxOption {
  margin-left: 0px;
}

.checkbox-filter {
  width: 12px;
}

.section-filter-title {
  margin: auto 16px;

  > p {
    height: 16px;
    color: #5E5E5E;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    font-family: 'Helvetica Neue', sans-serif;  }
}
