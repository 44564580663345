.transitTime {
  width: 100%;
  min-width: max-content;

  .content {
    .list-content {
      grid-template-columns: 2fr 1fr 1fr 1fr;
      margin-top: 24px;
      padding: 24px 0;
      >thead {
        >tr {
          >th {
            padding: 24px 12px;
            justify-content: flex-start;
          }

          > th:first-of-type {
            padding-left: 32px;
          }
        }
      }

      >tbody {
        >tr {
          >td {
            padding: 24px 12px;
          }

          > td:first-of-type {
            padding-left: 32px;
          }

          >td.emptyData {
            padding: unset;
            margin: 24px 12px;
          }
        }
      }
    }
  }

  &.BR{
   & .list-content {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }
  }

  .page-header {
    .right-component {
      flex: 1;
      margin-left: 24px;
    }
  }
}

.saveChanges-listRegion {
  height: 14px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  text-transform: none;
}

.edit-listRegion {
	height: 17px;
	color: #758CA5;
	font-size: 14px;
	font-weight: 500;
  line-height: 16px;
  margin-right: 16px;
  font-family: 'Helvetica Neue', sans-serif;
  text-transform: none;
}

.cancel-listRegion {
	height: 17px;
	color: #758CA5;
	font-size: 14px;
	font-weight: 500;
  line-height: 16px;
  font-family: 'Helvetica Neue', sans-serif;
  text-transform: none;
}

.edit-icon-div {
  margin: auto;
  height: fit-content;
  width: fit-content;
}

.settingsRegion-buttonDiv {
  width: 100%;
  background-color: #FFFFFF;
  .settingsRegion-buttonWrapper {
    margin-left: auto;
    width: fit-content;
  }
}

.settingsTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleDot {
  width: 12px;
  height: 12px;
  background-color: black;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

#transitTimeModal {
  background-color: #FFFFFF;
  width: 424px;
  height: 180px;

  margin: auto;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  outline: none;

  #transitTime-closeModal {
    margin-top: 16px;
    margin-left: 24px;
    width: fit-content;
    cursor: pointer;
  }

  #transitTime-modalMessage {

    margin-top: 16px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 24px;

    #transitTime-boldMessage {
      font-weight: 500;
    }
  }

  #transitTime-modalButtons {
    text-align: right;

    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 16px;

    border-top: 1px solid #657DD9;

    #transitTime-modalButtonsWrapper {
      padding-top: 16px;
      display: inline-flex;

      #transitTime-cancelButton {
        margin-right: 8px;
      }
    }

  }
}
