@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.versionModal-labels {
  display: flex;
  justify-content: center;
  padding: 0 24px 24px 24px;

  > div ~ div {
    margin-left: 16px;
  }

  .versionModal-input {
    display: flex;
    flex-direction: column;

    .versionModal-title {
      @extend .font-small2;
      color: $textSecondaryColor;
    }

    > input {
      text-indent: 8px;
    }

    > input:focus {
      outline: none;
    }

    .versionModal-inputVersion{
      border-radius: 4px;
      border: 1px solid #ACBAC9;
      margin-top: 6px;
      width:230px;
      height: 36px;
    }

    .versionModal-inputCode{
      @extend .versionModal-inputVersion;
      width: 132px;
    }
  }
}
