.dashboardPedidosComerciaisTitle {
  height: 29px;
  color: #0c1c2d;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: 10px;
  margin-left: 32px;
}
.dashboardPedidosComerciaisSubtitle {
	height: 19px;
	color: #5E5E5E;
  font-family: 'Helvetica Neue', sans-serif;
	font-size: 16px;
	font-weight: bold;
  line-height: 19px;
  text-align: right;
}
.dashboardMainTitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.dashboard-kpi {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.dashboard-kpi-subtitle {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 8px;

  .dashboard-kpi-subtitle-label {
    font-family: inherit;
    font-size: 16px;
    color: #5E5E5E;
    font-weight: bold;
  }

  .dashboard-kpi-subtitle-value {
    margin-left: 8px;
    font-family: inherit;
    font-size: 20px;
    font-weight: bold;
    color: #5E5E5E;
  }
}

.graph-div {
  position: relative;
}

.percent-components {
  display: flex;
  flex-direction: row;
  width: calc((100% - 32px) / 5 * 3 );
}

.pendent-components {
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  margin-right: 0;
}

.pendent-components-dealer {
  width: calc((100% - 32px) / 5 * 2 );
}

.resume-components-dealer {
  width: calc(100% / 2);
}

.resume-components-customerCare {
  width: calc(100% / 4);
}

.pendent-components-customerCare {
  width: calc((100% - 32px) / 5 * 4 );
}

.percent {
	height: 144px;
  width: calc(100% / 3);
}

.pendent {
	height: 144px;
}

.pendent-quantity {
  margin-top: auto;
  margin-bottom: 24px;
}

.percent-quantity {
  margin-top: 12px;
}

.resumeIcon-wrapper {
  width: 12px;
  height: 14px;
}

.resume-title-div {
  display: inherit;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .resumeIcon-wrapper {
    fill: #758CA5;

  }
  .resume-title {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    background-color: none ;
  }
}

.dashboard-order-status-item {
  .late {
    background-color: #FD003E;
    color: #FD003E;
  }

  .risk {
    background-color: #DD9304;
    color: #DD9304;
  }

  .beTimes {
    background-color: #758CA5;
    color: #758CA5;
  }
}

.rightResume {
  background-color: #758CA5;
  width: 100px;
  color: #758CA5;
}

.section-status {
  max-width: 48%;
  border-radius: 4px;
}

.dashboard-order {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #FFFFFF;
  height: auto;
  margin: 0px 0px 12px 0px;
  width: 100%;

  .dashboard-order-status-item {
    display: grid;
    grid-template-columns: 16% 38% 30% 16%;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #E5EAEE;
    height: 80px;
    text-align: left;
    th {
      height: 12px;
      color: #758ca5;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
    }
    td {
      align-items: center;
      text-align: left;
    }

    .dashboard-order-status-item-wrapper {
      display: flex;
      align-items: baseline;
      width: 100%;
    }

    .resume-dot {
      height: 12px;
    	width: 12px;
      border-radius: 50%;
      margin-right: 24px;
    }

    .resume-title {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      background-color: transparent;
      text-align: left;
    }

    .resume-quantity {
      display: flex;
      justify-content: flex-start;
      color: #5E5E5E;
	    font-size: 16px;
	    font-weight: bold;
	    text-align: center;
      width: 148px;
    }

    .resume-percent {
      display: flex;
      justify-content: flex-start;
      height: 16px;
      color: #758CA5;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      width: 80px;
    }
  }
  .firstLine {
    border-top: none;
    height: 40px;
    margin-top: 32px;
  }
}

.section-kpi-csi {
  margin-left: 24px;
  border-radius: 4px;
}

.resume-commercial-orders {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.dashbord-order-status-title {
  height: 29px;
  font-size: 23px;
  font-weight: bold;
}

.dashbord-order-status-table {
  .dashbord-order-status-subtitle-wrapper {
    .dashbord-order-status-subtitle {
      height: 12px;
      width: 77px;
      color: #758CA5;
      font-size: 10px;
      font-weight: bold;
      line-height: 12px;
    }
  }
}

.dashboardPedidosComerciais {
  margin-top: 16px;
}

.dashboardPedidosIndustriais {
  margin-top: 32px;
}

.dashboard-kpi-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >.empty-state-icon {
    width: 80px;
    height: 80px;
  }

  >span {
    height: 19px;
    color: #5E5E5E;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    margin-top: 16px;
  }
}

.dashboardKpiCsiHistory {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 32px;

  .dashboardKpiCsiHistory-graph {
    display: flex;
    align-self: center;
    width: 1000px;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1442px) {
  .dashboard-order {
    min-width: 677px;
  }
  .section-status {
    max-width: none;
  }
}
