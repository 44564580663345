
.list-content {
  .list-header{
    display: flex;
    text-align: left;
    flex-direction: row;
    border-bottom: 1px solid #E5EAEE;

    > div:last-child {
      text-align: right;
      padding-right: 10px;
    }
  }

  &.plan-ahorro{
    & .labelWithIconDiv .icon{
      display: none;
    }
  }

  .header-item {
    border-top: 1px solid #E5EAEE;
    color: #758CA5;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    text-align:start;
    white-space: nowrap;
    height: 28px;
  }

  th:last-of-type {
    display: flex;
    justify-content: flex-end;
  }

  .header-item-edge-right{
    color: #758CA5;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    margin-bottom: 10px;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }
  > thead {
    > tr {
      > th:first-of-type {
        padding: 4px 12px 4px 32px;
      }

      > th:last-of-type {
        padding: 4px 32px 4px 12px;
        justify-content: flex-end;
      }
    }
  }

  > tbody {
    > tr {
      > td:first-of-type {
        padding: 12px 12px 12px 32px;
      }

      > td:last-of-type {
        padding: 16px 32px 16px 12px;
        align-items: flex-end;
      }
    }

    > a > tr {
      > td:first-of-type {
        padding: 12px 12px 12px 32px;
      }

      > td:last-of-type {
        padding: 16px 32px 16px 12px;
        align-items: flex-end;
      }
    }
  }
}
