@import '../../../Utils/Styles/colors.scss';

.resultResponse {
  text-align: center;

  .resultResponse-title, .resultResponse-subtitle {
    color: $textPrimaryColor;
  }

  .resultResponse-title {
    margin-bottom: 4px;
  }
}
