@import "../../../Utils/Styles/colors.scss";

.commercialClientBody {
  background-color: #eceef2;
  width: 100%;
  height: 100%;
  display: flex;

  .rightIndustrialCard {
    margin: 24px;
    width: 100%;
    .orderInformationCard-wrapper {
      margin-top: unset;
    }
    .timeline-body {
      min-width: 1200px;
      max-width: 1500px;
      margin: auto;
    }
  }

  .detailsComercialTitle {
    margin-left: 40px;
    margin-top: 40px;
    cursor: pointer;
  }

  .detailsComercialTitle[class*="skeleton"] {
    width: 240px;
    height: 16px;
  }

  .skeleton {
    border-radius: 5px;
    background-color: rgba(229, 234, 238, 0.3);
  }

  .comercialTitle {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-decoration: none;
    padding-left: 12px;
  }

  .pedidoComercial {
    background-color: #ffffff;
    width: 40%;
    min-width: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .industrialOrder {
    width: 100%;
    overflow-y: auto;
  }

  .industrialOrderDetailEmpty {
    margin: 24px;
    width: 100%;
    >div {
      display: flex;
      background-color: white;
      min-width: 1200px;
      max-width: 1500px;
      align-self: center;
      margin-right: auto;
      margin-left: auto;
      div {
        margin: 40px 24px;
        width: 100%;
        background-color: #ebedf2;
      }
    }
    .informationCard {
      margin-bottom: 24px;
      height: 140px;
    }
    .timeLineCard {
      height: 380px;
      .empty {
        margin-top: 150px;
        margin-bottom: 150px;
      }
    }
  }

  .siebelComponent {
    background-color: #ffffff;
  }

  #noIndustrialOrders {
    background-color: #ffffff;
    width: 100%;
    display: flex;

    #noIndustrialOrdersCenterWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      #noIndustrialOrdersMessage {
        margin-top: 24px;
        color: #758ca5;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
    }
  }

  .timeLineMobileEmpty {
    width: 90%;
    background-color: white;
    height: 600px;
    margin: auto;
    .empty {
      background-color: #eceef2;
      width: 80%;
      height: 80%;
      margin: 68px auto;
    }
  }
}


.employeeWelcomeModal-wrapper {
  .baseModal-content {
    width: 90%;
    max-width: 460px;
    min-width: 330px;
  }
  .employeeWelcomeModal {
    position: relative;

    .employeeWelcomeModal-closeIcon {
      width: 12px;
      height: 12px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      margin: 16px 16px 0px 0px ;
      z-index: 1;
    }
    .employeeWelcomeModal-image {
      width: 100%;
      height: 300px;
      display: flex;
      border-radius: 5px 5px 0 0;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, rgba(12, 28, 45, 0.8), rgba(12, 28, 45, 0.8)), url("../../../Common/Assets/fca-betim.jpg");
      object-fit: cover;
      background-size: cover;

      .feather-logo {
        height: 30px;
        width: 150px;
      }

      .divisao {
        height: 30px;
        margin: 0 30px 0 30px;
      }

      .easy-tracking {
        width: 150px;
        font-size: 16px;
      }
    }

    .employeeWelcomeModal-text {
      padding: 32px;

      h5 {
        text-align: center;
        margin-bottom: 16px;
      }

      span {
        display: block;
        white-space: pre-wrap;
        text-align: justify;
        color: $textBlackColor;
      }
    }
  }
}

@media only screen and (max-width: 812px) {
  .private-pages .client-content {
    flex-direction: column;

    .client-menu {
      width: 100%;
      height: 56px;

      .menu {
        width: 100%;
        height: 56px;
        flex-direction: row;
        overflow-y: hidden;

        .endMenuItems {
          flex-direction: row;
          margin-left: auto;
        }
      }
    }
  }

  .commercialDetails {
    background-color: white;
    .commercialOrderDetail {
      width: 100%;
    }
  }

  .commercialClientBody {
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-width: 364px;
    overflow-x: unset;

    #timelineWrapperEmpty {
      display: none;
    }

    #siebelWrapperEmpty {
      display: none;
    }

    .timeline-body {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 32px;
      max-width: unset;
      width: 100%;
      min-width: 440px;
      margin-bottom: 48px;
      #timelineWrapper {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        margin: 0px 0 16px;
        max-width: unset;

        .clientLegalTerm {
          padding: 0 16px 16px 16px;
          text-align: justify;
        }

        .timeline-card {
          display: flex;
          margin: 0px 0px 16px;
          height: unset;
          border-radius: 4px;
          justify-content: center;
          overflow-y: hidden;
          overflow-x: auto;

          .timeline-grid {
            margin: 0;
            grid-auto-columns: minmax(40px, 1fr) minmax(50px, 2fr);
            padding-bottom: 0px;
            min-width: 330px;
          }
        }
      }
    }

    .commercialDetails {
      height: fit-content;
      padding: 24px 24px 0px 24px;
      position: relative;
      .commercialOrderDetail .phoneContact {
        margin-bottom: 24px;
      }
    }
    .industrialOrder {
      flex-direction: column;
      overflow-y: hidden;
      width: fit-content;
      min-width: 100%;

      #industrialDetailsDiv {
        margin: 0px 16px;
        width: auto;
        padding: 0;
      }
    }

    .pedidoComercial {
      min-width: 440px;
      width: 100%;
      height: fit-content;
      position: relative;
      background-color: transparent;
      padding-bottom: 20px;
    }

    .pedidoIndustrial-downloadInvoiceDiv {
      display: grid;
      grid-template-areas:
      "nf button"
      "text button";
      grid-template-columns: 1fr auto;
      margin-top: 0;
      padding: 16px;
      margin-bottom: 48px;

      .pedidoIndustrial-downloadInvoiceIcon {
        grid-area: nf;
      }

      .pedidoIndustrial-downloadInvoiceDiv-text{
        grid-area: text;
        font-size: 12px;
        text-align: left;
        color: $textSecondaryColor;
        margin: 4px 0 0 0;
      }

      .pedidoIndustrial-downloadInvoiceDiv-button{
        grid-area: button;
        font-size: 12px;
        width: 100px;
        height: 31px;
        justify-self: flex-end;
      }
    }
  }

}

.commercialClientListing {
  height: 100%;
  width: 100%;
  min-width: 391px;
}
