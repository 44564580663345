@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.unregisteredDataModal {
  .unregisteredDataWarning {
    display: flex;
    padding: 8px;
    border-radius: 4px;
    background-color: #fff5d9;
    margin-right: 16px;
    p {
      color: $warningColor;
      @extend .font-small2;
    }

    svg {
      margin-right: 8px;
      fill: $warningColor;
      vertical-align: middle;
    }

    .expandArrowIcon {
      margin-left: 8px;
      margin-right: 0;
      cursor: pointer;

      #path-expand-arrow {
        fill: $warningColor;
      }
      #path-expand-background {
        fill: none;
      }
    }
  }
}

#unregisteredData-popper {
  margin-top: 8px;
  width: 282px;
  height: 300px;
  z-index: 2;
  border-radius: 4px;
  background-color: $backgroundSecondaryColor;
  box-shadow: 0px 0px 2px rgba(85, 87, 112, 0.08), 0px 4px 8px rgba(85, 87, 112, 0.16);

  .unregisteredData-article {
    height: 100%;

    .unregisteredData-section {
      .unregisteredData-header {
        @extend .font-subtitle1;
        padding: 16px;
        box-shadow: inset 0px -1px 0px $borderAndDividerColor;
        color: $textBlackColor;
      }
      .unregisteredData-content {
        overflow-y: auto;
        max-height: 244px;

        .unregisteredData-modelVersions {
          padding: 8px 16px;
          box-shadow: inset 0px -1px 0px rgba(237, 237, 237, 0.5);
          .model-content {
            @extend .font-small1;
            color: $textBlackColor;
          }
          .version-content {
            @extend .font-small3;
            color: $textPrimaryColor;
            line-height: 14px;
          }
        }
      }
    }
  }
}
