.modelVersionForecast-table {
  .listModelVersionForecast-item {
    > td {
      padding: 16px 12px;
    }

    > td:first-of-type {
      padding: 16px 12px 16px 32px;
    }

    > td:last-of-type {
      padding: 16px 32px 16px 12px;
      align-items: flex-end;
      line-height: 16px;
    }

    .item-bolder {
      font-weight: 500;
    }
    .limitDeliveryDate {
      color: #758ca5;
      font-size: 12px;
      line-height: 14px;
      margin-left: 8px;
    }
    .calendar-icon {
      line-height: 14px;
    }
  }
}
