@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.uploadButton {

  input[type="file"] {
    display: none;
  }

  .uploadButton-div {
    height: 34px;
    width: 96px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid $textSecondaryColor;
    background-color: $actionPrimaryColor;

    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .uploadButton-title {
      @extend .font-button;

      color: $actionSecondaryColor;
      margin: 0 4px 0 8px;
    }
  }

  .progress-bar {
    color: $actionSecondaryColor;
  }
}

