.private-pages-content-components {
  height: 100%;
  .dashboardPage {
    display: grid;
    grid-template-columns: 18% 82%;
    height: 100%;
    min-width: 800px;
  }
}

@media only screen and (max-width: 1240px) {
  .private-pages-content-components {
    .dashboardPage {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 108px 1fr;
    }
  }
}
