@import '../../../Utils/Styles/colors.scss';

.helpIcon-div {
  display: flex;
  align-items: center;
  cursor: pointer;

  .helpIcon {
    fill: #758CA5;
  }
}


