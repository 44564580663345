@import "../../../Utils/Styles/colors.scss";

.resizeButton-mobile {
  .resizeButton-mobile-button {
    box-shadow: 0px 3px 5px 3px rgba(12, 28, 45, 0.10);
    border: 1.5px solid $actionPrimaryColor;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: fit-content;
    }

    .resizeButton-mobile-icon {
      width: 14px;
      height: 14px;
      fill: $actionPrimaryColor;
    }
  }
}
