@import '../../../Utils/Styles/colors.scss';

.orderInformationCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: fit-content;
  min-height: 140px;
  background-color: $actionSecondaryColor;
  padding: 24px 16px;

  .orderInformationItem-label {
    text-transform: uppercase;
    color: $textSecondaryColor;
    white-space: nowrap;
  }

  &.pda {
    & .orderInformationCard-title, & .orderInformationCard-client-wrapper {
      margin-top: -50px;
    }

    &.no-order{
      padding-bottom: 0px;
    }

    &.false {
      & .orderInformationCard-title, & .orderInformationCard-client-wrapper {
        margin-top: 0px;
      }

    }
  }

  > div {
    padding: 8px;
    max-height: 100%;
  }

  .orderPendocTooltip {
    display: flex;
    width: fit-content;

    .orderInformationBlock-dealer > span {
      cursor: default;
    }

  }
  .orderInformationCard-title {

    >.tagOrderStatus {
      position: absolute;
      margin-top: -28px;
    }

    >.font-small2 {
      position: absolute;
      margin-top: 4px;
    }

    > div {
      display: flex;
      white-space: nowrap;

      > h3 {
        color: $textBlackColor;
        text-transform: capitalize;
        white-space: nowrap;
        margin-right: 24px;
      }
    }

    > span {
      color: $textSecondaryColor;
    }
  }

  .orderInformationCard-client-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;


    .orderInformationCard-alerts {

      > svg {
        fill: $errorColor;
        margin-top: 24px;
        width: 16px;
        height: 18px;
      }

      > svg ~ svg {
        margin-left: 8px;
      }
    }

    .orderInformationCard-client {
      display: flex;
      flex-direction: row;
      align-items: center;

      :first-child {
        margin-right: 4px;
      }

      .orderInformationCard-clientDocument {
        color: $textSecondaryColor;
      }

      .orderInformationCard-clientName {
        width: fit-content;

        > span {
          width: fit-content;
          max-width: 150px;
        }
      }
    }
  }

  .orderInformationCard-model {
    display: flex;
    flex-direction: row;

    > div ~ div {
      margin-left: 24px;
    }

    &.pda {
      flex-direction: column;

      gap: 8px;

      .-model {
        display: flex;
        flex-direction: row;

        > div ~ div {
          margin-left: 24px;
        }
      }

    .marca-solicitud {
      display: flex;
      gap: 24px;

      margin-left: 0px;

      & .marca, .solicitud {
        display: flex;
        flex-direction: column;
      }
    }
    }
  }

  .orderInformationCard-dealer {
    display: flex;
    flex-direction: row;
    width: fit-content;
    max-width: 200px;

    > div ~ div {
      margin-left: 24px;
    }

    &.pda {
      flex-direction: column;

      gap: 8px;

      & .orderInformationCard-client-wrapper{
        margin-top: 0px;
      }
    }

    &.no-order {
      flex-direction: row;
      max-width: 100%;
      min-width: 700px;
      gap: 32px;

      margin-top: -40px;

      & .clientName{
        max-width: 200px !important;
      }

      & .marca, .solicitud{
        display: flex;
        flex-direction: column;
      }
    }

    .grupo-orden {
      display: flex;
      flex-direction: column;

      margin-left: 0px;
    }
  }
  .orderInformationBlock-dealer {
    display: flex;
    width: fit-content;
    flex-direction: row;
    margin-top: 8px;
    > span {
      margin-left: 6px;
      color: $errorColor;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      font-style: normal;
    }
  }
}
