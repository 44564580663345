@import "../../../Utils/Styles/colors.scss";

.pedidoIndustrial-downloadInvoiceDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  >svg {
    margin-left: 40px;
    fill: #026341;
  }

  >span {
    margin-left: 16px;
    color: #026341;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .pedidoIndustrial-downloadInvoiceDiv-button {
    margin-left: 16px;
    height: 32px;
    width: 110px;
    border-radius: 3px;
    background-color: $actionPrimaryColor;
    color: $actionSecondaryColor;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    text-transform: unset;

    &:hover {
      background-color: $actionPrimaryColor;
    }
  }
}
