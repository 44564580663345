.delayNotifications-drawerContent {
  padding: 24px;
  width: min-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  > .delayNotifications-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    > svg {
      cursor: pointer;
    }
  }

  > .tabs-selector {
    margin-bottom: 8px;
    margin-top: 0;
  }

  .delayNotifications-notificationsList {
    height: 100%;
    overflow-y: scroll;
  }

  .delayNotifications-emptyState {
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;

    > svg {
      margin-bottom: 16px;
      width: 80px;
      height: 80px;
    }

    > h5 {
      margin-bottom: 8px;
    }

    > p {
      margin-bottom: 16px;
    }
  }
}
