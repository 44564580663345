@import '../../../Utils/Styles/colors.scss';

.industrialOrderCard {
  margin: auto;
  width: 250px;
  height: 180px;
  padding: 20px 10px 20px 20px;
  border-radius: 4px;
  background-color: $actionSecondaryColor;
  cursor: pointer;

  > div ~ div {
    width: 200px;
  }

  .industrialOrderCard-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    .industrialOrderCard-step {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 90px;

      .font-small1 {
        width: 90px;
        word-wrap: break-word;
        text-align: center;
        margin-top: 4px;
        position: absolute;
        top: 100%;
        line-height: 14px;
      }

      .step-delivered, .step-onTime, .step-ongoing {
        background-color: unset;
        color: $actionPrimaryColor;
      }

      .step-inRisk {
        background-color: unset;
        color: $warningColor
      }

      .step-late {
        background-color: unset;
        color: $errorColor
      }
    }
  }

  .industrialOrderCard-information {
    > h5 {
      color: $textBlackColor;
      margin-bottom: 4px;
    }
    > span {
      color: $textSecondaryColor;
    }
  }

  .industrialOrderCard-alerts {
    display: flex;
    margin-top: 8px;
    justify-content: flex-start;
    align-items: center;

    div ~ div {
      margin-left: 24px;
    }
  }

}

.industrialOrderCard.cardSelected {
  border: 2px solid $actionPrimaryColor;
}

.industrialOrderCard.cardNormal {
  border: 2px solid $actionSecondaryColor;
}
