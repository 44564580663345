.pedidos {
  height: 70%;
  width: fit-content;
  min-width: 100%;


  .content {
    .pedidos-list {
      background-color: white;
      margin-top: 24px;
      padding-bottom: 34px;

      .pedidos-filters {
        display: flex;
        flex-direction: row;
        padding: 24px 30px;
        width: 100%;
        min-width: 1000px;

        > div {
          div ~ div {
            margin-left: 24px;
          }
        }

        .searchInput {
          .search-component {
            .MuiFormControl-root {
              width: 490px;
            }
          }
        }
      }

      .list-content {
        margin-top: 0;
        grid-template-columns: 9fr 10fr 12fr 21fr 14fr 12fr 11fr 11fr;
        padding: 0px;

        &.plan-ahorro{
          grid-template-columns: 8fr 10fr 9fr 10fr 15fr 13fr;

          & thead tr th:last-of-type {
            justify-content: flex-start;
          }
        }
      }
      .list-skeleton-wrapper {
        margin-top: 24px;
      }
    }
  }
  .content.AR {
    .list-content {
      grid-template-columns: 0.5fr 1fr 3fr 1fr 1fr 1fr;
    }
  }
  .totalVisibleOrdersDiv {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 24px;
  }

  .totalVisibleOrdersSpan {
    margin: auto;

    color: #758ca5;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

}

.dataExtenso {
  white-space: nowrap;
}

.content {
  margin-top: 49px;
  width: fit-content;
  min-width: 100%;
  padding: 0px 24px 24px;

  .tabs-selector {
    margin-bottom: -17px;
  }

  #emptyState {
    margin-top: 64px;
  }
}

#ordersTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: inherit;
}

#dealerNameDiv {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  span {
    margin-left: 4px;
  }
}

#dotWraper {
  margin: auto;
  margin-left: 12px;
  margin-right: 8px;
}

.carregarMaisDiv {
  margin: auto;
  margin-top: -32px;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  align-items: center;
  background-color: #FFFFFF;

  .totalVisibleOrdersDiv {
    margin-top: unset;
  }

  .list-skeleton-wrapper {
    padding: 0px 32px 32px 32px;
  }
}

.carregarMaisButtonDiv {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 24px;
}

.listPedidos-title {
  margin-top: 32px;
  margin-bottom: 24px;
  margin-left: 32px;
  font-weight: bold;
  line-height: 29px;
  color: #0c1c2d;
  font-size: 24px;
  height: 29px;
}

#emptyState {
  width: 100%;
  height: 100%;

  display: flex;

  align-content: center;

  #emptyState-content {
    width: fit-content;
    height: fit-content;

    margin: auto;
    display: flex;

    flex-direction: column;

    align-items: center;
  }

  #emptyState-text {
    margin-top: 24px;
    font-weight: 500;
    color: #5e5e5e;
  }
}

.pedidosToolTipDiv {
  display: flex;
  flex-direction: column;
}

.text-align-right {
  text-align: right;
}

.dashboard-content-button-wrapper {
  padding: 0px 32px 32px 32px;
  margin-top: -32px;
  background-color: #FFFFFF;
}

.dashboard-content-button {
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 159px;
  background-color: #bac5d2;
  border-radius: 5px;
  margin-left: auto;
}

.dashboard-button-unselected {
  display: flex;
  width: 50%;
  height: 32px;
  color: white;
  background-color: #bac5d2;
  border-radius: 5px;
  border: none;
}

.dashboard-button-unselected:hover {
  cursor: pointer;
}

.dashboard-button-selected {
  display: flex;
  width: 50%;
  height: 32px;
  color: #0c1c2d;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #bac5d2;
}

.dashboard-button-selected > span,
.dashboard-button-unselected > span {
  display: flex;
  margin: auto;
  font-size: 14px;
  font-weight: 500;
}
