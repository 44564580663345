.loginExpired-modal {
  width: 385px;
  height: 205px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.loginExpired-message{
  text-align: center;
}
