@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.modelModal {
  padding: 0 24px 24px 24px;

  .modelModal-title {
    @extend .font-small2;
    color: $textSecondaryColor;
    margin-bottom: 6px;
  }

  .modelModal-labels {
    display: flex;
    justify-content: center;
    > div ~ div {
      margin-left: 16px;
    }
    .modelModal-input {
      display: flex;
      flex-direction: column;

      > input {
        text-indent: 8px;
      }

      > input:focus {
        outline: none;
      }

      .modelModal-inputVersion{
        border-radius: 4px;
        border: 1px solid #ACBAC9;
        width:230px;
        height: 36px;
      }

      .modelModal-inputCode{
        @extend .modelModal-inputVersion;
        width: 132px;
        height: 36px;
      }
    }
  }

  .modelModal-selector {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 377px;

    .css-1pahdxg-control{
      border-color: #ACBAC9;
      box-shadow: none;
    }

    .css-yk16xz-control {
      border-color: #ACBAC9;
    }

    .css-1okebmr-indicatorSeparator {
      background-color: #ACBAC9;
    }
  }
}

