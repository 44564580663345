#simple-popover {
  background-color: white;
  border: 1px solid #c7ced8;
  box-sizing: border-box;
  box-shadow: -1px 8px 23px -6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 4px !important;
  left: 0px !important;

  .filterPanels > div {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid #ebedf2;
  }
}
