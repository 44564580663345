.error-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 32px 0;

  > span {
    margin-top: 24px;
    margin-bottom: 24px;
    white-space: pre-wrap;
    text-align: center;
  }
}
