@import "../../../Utils/Styles/fonts.scss";
@import "../../../Utils/Styles/colors.scss";

.textListing {
  display: flex;
  flex-direction: row;
  span {
    @extend .font-small3;
    color: $actionPrimaryColor;
  }
  .textListingContent {
    display: contents;
  }
  .singleText {
    display: flex;
    align-items: center;
    background-color: $backgroundPrimaryColor;
    padding: 4px 8px;
    border-radius: 3px;
    cursor: default;
  }
  .singleText.visible:nth-child(1) {
    background-color: #8AFFE5;
  }
  .singleText ~ .singleText {
    margin-left: 8px;
  }
}
.hiddenList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 152px;
  white-space: pre-wrap;
  text-align: start;
  .hiddenListLine {
    display: flex;

    span ~ span {
      margin-left: 6px;
    }
  }
}
