@import "../../../Utils/Styles/colors.scss";

.delayManagementDetails {
  background-color: white;
  border-radius: 4px;

  .cardOrder-header {
    padding: 24px 24px 0 24px;

    .tagOrder-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      .brand-logo {
        height: 25px;
      }

    }

    h4.title-industrialOrder {
      font-weight: 700;
    }

    .subtitle-order {
      color: $textSecondaryColor;

      > span {
        text-transform: capitalize;
      }
    }
    .commercialOrderCallMade-icon {
      margin-left: 8px;
    }
  }

  .cardOrder-body {
    padding: 0px 24px;

    .image-vehicle {
      margin: 24px auto;
      width: fit-content;

      .car-image {
        height: 100px;
      }
    }

    .orderInformationCard-client {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
    }

    .orderInformationCard-clientDocument {
      color: $textSecondaryColor;
    }

    .orderInformationCard-clientName {
      margin-left: 16px;
      width: fit-content;

      > span {
        margin: 0;
        width: fit-content;
        max-width: 200px;
        font-size: 12px;
      }

      .cpfText {
        font-weight: normal;
      }
    }

    .orderInformationCard-dealer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
    }

    .orderInformationCard-dealerInfo {
      color: $textSecondaryColor;

      .dealerCounty {
        text-transform: capitalize;
      }
      .dealerName {
        text-transform: capitalize;
      }
    }

    .orderInformationCard-dealerTitle {
      margin-left: 16px;
      width: fit-content;
      margin-top: auto;

      > span {
        margin: auto;
        width: fit-content;
        max-width: 150px;
      }
    }
  }

  .dot {
    color: #758ca5;
    font-weight: 900;
  }

  .line-division {
    border: 0;
    border-top: 1px solid #EBEDF2;
  }

  .status {
    padding: 16px 24px 24px 24px;

    .stepInformation {
      display: flex;
      flex-direction: row;
    }

    .tagOrderStatus {
      margin-bottom: 4px;
    }

    .statusIcon {
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .statusIcon-line {
        margin-bottom: -41px;
        margin-top: -24px;
      }
    }

    .status-title {
      color: inherit;
      font-weight: 500;
      margin-top: 4px;
    }

    .foreseen-title {
      color: inherit;
      font-weight: 500;
    }

    .overdueData {
      color: $textSecondaryColor;
      margin-top: 8px;

      .overdueDate-title {
        font-weight: 500;
      }

      .overdueDate-data {
        text-transform: capitalize;
      }
    }

    .orderDelayReasons {
      margin-top: 16px;
      margin-left: 48px;

      .reason-title {
        color: inherit;
        font-weight: 500;
        margin-top: 8px;
      }

      .reasonListing {
        color: inherit;
        margin-top: 4px;
        padding-inline-start: 16px;
      }
    }
  }

  .status.late {
    color: $errorColor;
  }

  .status.inRisk {
    color: $warningColor;
  }
}

@media only screen and (max-height: 768px) {
  .delayManagementDetails {
    .car-image {
      display: none;
    }
  }
}
