@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

#feedbackModal {
  box-sizing: border-box;
  height: 100%;
  max-width: 450px;
  width: 95%;
  border: 1px solid rgba(117, 140, 165, 0.5);
  border-radius: 4px;
  background-color: #ffffff;
  margin: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  outline: none;
  text-align: center;
  max-height: 616px;
  overflow: auto;
  overflow-y: hidden;
  z-index: 2;

  .feedbackModal__footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .feedbackModal__body {
    height: fit-content;
  }

  header h1 {
    color: $textBlackColor;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 16px;
    font-family: inherit;
  }

  p.feedbackModal__intro {
    color: #5e5e5e;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0;
    margin-bottom: 16px;
    font-family: inherit;
  }

  .fieldLabel {
    color: $textBlackColor;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 8px;
    font-family: inherit;
  }

  .radioLabel {
    color: #5e5e5e;
    font-size: 12px;
    line-height: 21px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    font-family: inherit;
    margin: 0 22px 0 22px;
  }

  .radioContainer {
    width: 100%;
  }

  .avaliationLegend {
    display: block;
    width: 330px;

    .avaliationLabel {
      color: #758ca5;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
    }

    .labelLeft {
      float: left;
    }

    .labelRight {
      float: right;
    }
  }

  .feedbackModal__nps {
    margin-top: 12px;
    margin-bottom: 20px;

    .feedbackModal__radioGroup {
      justify-content: center;
    }
  }

  .feedbackModal__categories {
    margin-bottom: 16px;
  }

  .feedbackModal__textarea {
    resize: none;
    outline: none;
    box-sizing: border-box;
    height: 101px;
    width: 100%;
    border: 1px solid #657dd9;
    border-radius: 4px;
    color: #5e5e5e;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 16px;
    :focus {
      border: 1px solid #657dd9;
    }
  }

  .feedbackModal__warning {
    @extend .font-small2;
    margin-top: 16px;
    color: $textSecondaryColor;
  }

  .feedbackModal__cancel {
    box-sizing: border-box;
    height: 32px;
    width: 85px;
    border: 1px solid rgba(117, 140, 165, 0.5);
    border-radius: 3px;
    background-color: #ffffff;
    margin-right: 16px;

    color: $textBlackColor;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .feedbackModal__send {
    height: 32px;
    width: 126px;
    border-radius: 3px;
    background-color: #657dd9;

    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;

    &:disabled {
      background-color: #babec9;
    }
  }
}

@media only screen and (max-width: 812px) {
  #feedbackModal {
    .radioLabel {
      margin: 0 11px 0 11px;
    }
  }
}
