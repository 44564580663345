@import '../../../Utils/Styles/colors.scss';

.private-pages-content-components {
  height: 100%;
  .parametersPage {
    display: grid;
    grid-template-columns: 18% 82%;
    height: 100%;
    min-width: 800px;

    .skeleton-item {
      background-color: $backgroundPrimaryColor;
      margin: 32px 12px;
      padding: 16px;
      width: auto;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .private-pages-content-components {
    .parametersPage {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 108px 1fr;
    }
  }
}
