.industrialOrderList {
  max-width: 1500px;
  margin: auto;

  .industrialOrderList-list {

    > div {
      height: 206px;
    }

    .industrialOrderList-centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      > svg {
        margin-bottom: 8px;
      }
    }

    .industrialOrderList-slider {
      margin: 30px;
    }

    .industrialOrderList-simpleList {
      margin-top: 30px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .industrialOrderCard {
        margin: 0px 40px 0px 0px;
      }
    }
  }
}
