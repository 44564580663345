.helpButton__feedbackTip {
  background-color: #FFFFFF;
  width: 350px;
  padding: 16px;
  border: 1px solid #D4D7DA;
  box-sizing: border-box;
  box-shadow: 4px 2px 12px -15px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 8px;

  .helpButton__feedbackTip__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;

    button ~ button {
      margin-left: 12px;
    }
  }
}

@media only screen and (max-width: 370px) {
  .helpButton__feedbackTip {
    width: 300px;
  }
}
