@import '../../../Utils/Styles/colors.scss';

button.notificationButton {
  width: 26px;
  height: 26px;
  padding: 0;
  border: none;
  border-radius: 4px  ;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;

  svg {
    fill: white;
    width: 16px;
    height: 16px;
  }
}

button.notificationButton.ERROR {
  background-color: $errorColor;
  &:hover {
    background-color: rgba($color: $errorColor, $alpha: 0.9);
  }
}

button.notificationButton.WARN {
  background-color: $warningColor;
  &:hover {
    background-color: rgba($color: $warningColor, $alpha: 0.9);
  }
}

button.notificationButton.INFO {
  background-color: $actionPrimaryColor;
  &:hover {
    background-color: rgba($color: $actionPrimaryColor, $alpha: 0.9);
  }
}


.notificationMessage-closeButton {
  width: 8px;
  height: 8px;
  margin: 8px 8px 0 0;
  cursor: pointer;

  &:hover {
    fill: $textBlackColor;
  }
}
