.baseModal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.baseModal-content {
  background-color: white;
  outline: none;
  border-radius: 5px;
}
