@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.inputNumber-wrapper {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 7px 16px 7px 8px;
  border-radius: 4px;
  border: 1px solid #acbac9;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > input:focus {
    background: none;
    border: none;
    outline: none;
  }

  > input {
    appearance: textfield;
    background: none;
    border: none;
    outline: none;

    width: 44px;
    height: 14px;

    margin: auto;

    @extend .font-small1;
    color: #898c8d;
  }

  > input::selection {
    color: unset;
  }

  > .inputNumber-arrows {
    display: flex;
    flex-direction: column;
    margin: auto;

    > .arrow {
      width: 8px;
      height: 8px;
      fill: $textSecondaryColor;
      cursor: pointer;
    }

    > .arrow-up {
      transform: rotate(270deg);
      margin-bottom: 4px;
    }

    > .arrow-down {
      transform: rotate(90deg);
    }
  }
}

.inputNumber-wrapper.edited {
  border-color: $actionPrimaryColor;

  > input {
    color: $actionPrimaryColor;
  }

  > .inputNumber-arrows {
    > .arrow {
      fill: $actionPrimaryColor;
    }
  }
}

.inputNumber-wrapper.focused {
  border-color: #555770;

  > input {
    color: #555770;
  }

  > .inputNumber-arrows {
    > .arrow {
      fill: #555770;
    }
  }
}
