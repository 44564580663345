@import '../../../../Common/Assets/Styles/variables.scss';

.title > h1.titulo-pedido{
  color: #0C1C2D;
  font-size: 36px;
  font-weight: bold;
  line-height: 43px;
}

.title > h1.codigo{
  color: #0C1C2D;
  font-size: 36px;
  font-weight: bold;
  line-height: 43px;
}

@media only screen and (max-width: 812px) {
  .commercialOrderDetail .surroundingBodyDiv {
    .titulo-pedido {
      width: auto;
      .title-section {
        width: auto;
        display: flex;
        flex-direction: column;

        .title {
          align-self: flex-start;
        }

        .statusAndBrand {
          display: flex;
          width: 100%;
          margin-top: 24px;
          justify-content: space-between;
        }
      }

    }
  }
}
