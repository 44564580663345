.list-item-pedidos {
  border-bottom: 1px solid #e5eaee;
  display: contents;

  .typeIcon {
    display: flex;
    align-items: center;
    padding-left: 24px;
  }

  &:hover {
    cursor: pointer;

    > td {
      background-color: rgba(#657dd9, 0.1);
    }
  }
}

.list-content {
  .header-item {
    display: flex;
    padding: 4px 12px;
    border-bottom: 1px solid #e5eaee;
    align-items: center;
  }

  td {
    padding: 14px 12px;
    border-bottom: 1px solid #e5eaee;
  }
}

.anchor-list-item {
  text-decoration: none;
}

.list-item-content {
  display: flex;
  flex-direction: row;
  height: 63px;
}

#empty-list-item-pedidos {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 63px;
  padding: 16px 0px;
}

#emptyTypeIcon {
  background-color: #ebedf2;
}

.emptyData {
  margin: auto;
  margin-left: 16px;
  height: 20px;
  width: 100%;
  background-color: #ebedf2;
}

.requestComDiv {
  display: flex;
  flex-direction: column;
}

.clientDataDiv {
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 140px;
}

.clientDataDiv .cpfText {
  font-weight: normal;
}

.estDelivery {
  display: flex;
  flex-direction: row;
}

.deliveryLimit {
  display: flex;
  flex-direction: row;
}

.onCallWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.numberRequests {
  text-align: right;
  min-width: 116px;
}

.orderTypeText {
  color: #657dd9;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-transform: uppercase;
  width: 34px;
  text-align: center;
}

.orderTypeDiv {
  display: flex;
  align-items: center;
}

td.alertsDiv {
  display: flex;
  flex-direction: row;
  padding: 14px 12px;
  margin: 0;
}

.onCallDiv {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

#onCall {
  height: 12px;
  font-size: 10px;
  line-height: 12px;
  color: #5e5e5e;
  margin-left: 8px;
}

#nRequests {
  height: auto;
  color: #5e5e5e;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  white-space: nowrap;
}

#limitDeliveryDays {
  height: 14px;
  color: #5e5e5e;
  font-size: 12px;
  line-height: 14px;
  margin-right: 16px;
}

#limitDeliveryDate {
  color: #758ca5;
  font-size: 12px;
  line-height: 14px;
  margin-top: 24px;
  padding-left: 4px;
}

#estDeliveryDate {
  color: #5e5e5e;
  font-size: 12px;
}

#clientDoc {
  color: #758ca5;
  font-size: 12px;
  line-height: 14px;
}

.clientName {
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#date {
  color: #758ca5;
  font-size: 12px;
  line-height: 14px;
}

#requestNumber {
  color: #657dd9;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
}

#icon {
  margin: auto;
  display: flex;
  width: 16px;
}

#calendar-icon {
  margin: auto;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 21px;
}

.alertsDivWrapper {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.alertsDiv {
  padding: 14px 12px;
}
