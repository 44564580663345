.macroAreaHistoryGraph {
  margin: auto;
  width: 90%;
  height: 90%;

  .macroAreaHistoryGraph-title {
    margin-bottom: 16px;
    height: 19px;
    color: #5E5E5E;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
  }
}

.macroAreaHistoryGraph-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .macroAreaHistoryGraph-loading {
    position: absolute;
  }
}
