.private-pages {
  display: flex;
  width: 100%;
  height: 100%;

  .container-fluid {
    width: 100%;
  }

  .private-pages-content {
    display: flex;
    width: 100%;
    height: 100%;

    .private-pages-content-menu {
      display: flex;
      height: 100%;
      flex-direction: column;
      position: relative;
      z-index: 2;
      box-shadow: 4px 2px 12px 0px rgba(0, 0, 0, 0.08);
    }
    .private-pages-content-components {
      width: 100%;
      overflow-y: auto;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      background-color: #EBEDF2;
    }
  }
}
