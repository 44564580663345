.linha {
  display: inline-flex;
  margin-top: 24px;
  text-transform: capitalize;
  cursor: default;
}

.linha .label {
  text-transform: uppercase;
}

.details-box{
  display: block;
  width: 368px;

  .linha:first-of-type {
    margin-top: 0;
  }
}

.details-box-dealer{
  display: block;
  width: 368px;
}

.details{
  display: inline-flex;
}

.optionals-wrapper{
  width: 300px;
  margin: 0px 32px 0px 0px;
}

.text-optional{
  width: 100%;
  margin: auto;
	color: #5E5E5E;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.label-optional{
  width: 100% !important;
	height: 12px;
	color: #758CA5;
	font-size: 10px;
  line-height: 12px;
  padding-top: 2px;
  text-transform: uppercase;
}
