@import '../../../Utils/Styles/colors.scss';

.filterTitle {
	color: $textBlackColor;
	font-weight: bold;
  margin: 16px 0px 16px 28px;
}

.filterHeader {
  display: inline-flex;
}

.closeButton {
  margin: auto 36px auto auto;
  cursor: pointer;
}

.ordersFiltersButtons {
  display: flex;
  flex-direction: row;
}

.datePickers {
  text-align: center;
}

.filterButton {
  border-top: 1px solid $backgroundPrimaryColor;
  padding: 16px 0px;
  display: flex;
  justify-content: space-evenly;
}

.filterButtonDates {
  padding: 32px 0px 0px 0px;
}

.filterPanels {
  height: fit-content;
  max-height: 460px;
  width: 380px;
  overflow-y: scroll;

  > div {
    border-style: solid;
    border-bottom: 1px solid $backgroundPrimaryColor;
  }

  :last-child {
    border-bottom: none;
  }
}

.filterPanels.AR {
  height: fit-content;
  max-height: 344px;
}

.text-button {
	height: 14px;
	width: 66px;
	color: #FFFFFF;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
  text-align: center;
  text-transform: capitalize !important;
}

.cleanAll-text {
	color: #5E5E5E;
	font-size: 12px;
	font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;
}

.filterDates {
  width: 290px;
  height: 358px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .font-subtitle1 {
    color: $textBlackColor
  }

  > div {
    border: none;
  }

  .filterDateSubtitle {
    margin-top: 4px;
  }
}

.searchInput {
  margin-left: auto;
}
