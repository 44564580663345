@import "../../../Utils/Styles/colors.scss";

.delayManagementList-list {
  .list-content {
    grid-template-columns: 15fr 19fr 21fr 16fr 19fr 7fr;
    padding: 0;
    margin-top: 0;
    width: 100%;

    > thead {
      > tr {
        > th {
          padding: 4px 12px;
          justify-content: flex-start;
          letter-spacing: 0.5px;
          border-bottom: 1px solid #E5EAEE;
          align-items: center;
          display: flex;
        }

        > th:first-of-type {
          padding: 4px 12px 4px 32px;
        }

        > th:last-of-type {
          padding: 4px 32px 4px 12px;
          justify-content: flex-end;
        }
      }
    }

    > tbody {
      > tr.selected {
        background-color: rgba($color: $backgroundPrimaryColor, $alpha: 0.5);
      }
      > tr {
        display: contents;
        &:hover {
          background-color: $backgroundPrimaryColor;
          cursor: pointer;
        }
        > td {
          display: flex;
          flex-direction: column;
          padding: 16px 12px;
          justify-content: center;
          background-color: inherit;
          height: 80px;
          border-bottom: 1px solid #E5EAEE;
        }

        > td.emptyData {
          padding: unset;
          margin: 24px 12px;
        }

        > td:first-of-type {
          padding: 12px 12px 12px 32px;
        }

        > td:last-of-type {
          padding: 16px 32px 16px 12px;
          align-items: flex-end;
        }
      }
    }

    .delayManagementList-model {
      .modelName {
        text-transform: capitalize;
      }

      .versionInfo {
        color: $textSecondaryColor;
        white-space: nowrap;
        font-weight: normal;
      }
    }

    .delayManagementList-step {
      .delayManagementList-stepDescription {
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }

    .delayManagementList-orderId {
      .orderId {
        margin-bottom: 4px;
      }
    }

    .status-late {
      color: $errorColor;
    }

    .status-inRisk {
      color: $warningColor;
    }

    .listModelVersionForecast-item.emptyList {
      :first-child {
        margin-left: 32px;
      }
      :last-child {
        margin-right: 32px;
      }
      .item-empty {
        height: 32px;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: $backgroundPrimaryColor;
      }
    }
  }
}
