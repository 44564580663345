@import '../../../Utils/Styles/colors.scss';

.orderInformationItem {
  display: flex;
  flex-direction: column;

  .orderInformationItem-label {
    text-transform: uppercase;
    color: $textSecondaryColor;
    white-space: nowrap;
  }

  .orderInformationItem-text {
    text-transform: capitalize;
    white-space: pre-wrap;
  }

  .orderInformationItem-label.disabled, .orderInformationItem-text.disabled {
    color: $borderAndDividerColor;
  }
}
