@import '../../../Utils/Styles/colors.scss';

.step-status-circle {
  margin: auto;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.step-status-circle-progress {
  position: relative;
  margin: auto;

  .step-status-circle-progress-circle {
    display: block;
    max-width: 100%;
    transform: rotate(270deg);

    .svg-circle {
      fill: none;
    }
  }
  .step-status-circle-progress-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.step-status-circle.delivered {
  background-color: $actionPrimaryColor;
}

.step-status-circle.late {
  border: solid $errorColor;
  background-color: rgba($color: $errorColor, $alpha: 0.15);

  > svg {
    fill: $errorColor;
  }
}

.step-status-circle.notStarted {
  border: solid rgba($color: $textPrimaryColor, $alpha: 0.2);
  background-color: $borderAndDividerColor;
}
