.transitTime-list-item {
  display: contents;
  .list-item-content {
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #E5EAEE;
    > div:first-child {
      text-align: left;
      margin-left: 10px;
    }
    > div:last-child {
      text-align: right;
    }
  }

  .transitTime-field {
    color: #5E5E5E;
    font-size: 14px;
    line-height: 16px;
    display: inline-flex;
    align-items: baseline;
    .transitTime-input-wrapper {
      text-align: center;
      display: flex;
      width: 30px;
    }
  }

  .transitionTime-editIcon {
    cursor: pointer;
  }

  .input-base-complement {
    color: #5E5E5E;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  .input-base-complement-editing {
    font-weight: bold;
  }

}

.list-item-editing {
  box-sizing: border-box;

  >td {
    border-top: 2px solid #657DD9;
    border-bottom: 2px solid #657DD9;
  }

  >td:first-of-type {
    border-left: 2px solid #657DD9;
  }

  >td:last-of-type {
    border-right: 2px solid #657DD9;
  }
}

.transitTime-list-item[editing] {
  border: 2px solid #657DD9;
  border-radius: 5px;
}

.text-info-transitTime {
  display: inline-flex;
}

.empty-list-item-transitTime {
  width: 100%;
  flex-direction: row;
  height: 63px;
}

.list-item {
  &:hover {
    cursor: default;
    background-color: white;
  }
}
