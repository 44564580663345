@import '../../../Utils/Styles/colors.scss';

.confirmationModal {
  .confirmationModal-wrapper {
    width: 400px;
    position: relative;
  }

  .confirmationModal-closeIcon {
    position: absolute;
    right: 28px;
    top: 28px;
    color: $textBlackColor;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  .confirmationModal-message {
    padding: 24px;
    .confirmationModal-text {
      margin-top: 16px;
    }
  }

  .confirmationModal-actions {
    padding: 24px;
    border-top: 1px solid $borderAndDividerColor;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button ~ button {
      margin-left: 16px;
    }
  }
}
