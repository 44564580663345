@import '../Utils/Styles/fonts.scss';

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: $fontFamily;
    font-size: 14px;
    overflow-y: hidden;
  }
}

.App {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  background-color: #FFFFFF;

}

/* width */
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px #C5C7D1;
  border-radius: 18px;
  border: 3px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 18px;
  background: #758CA5;
  border: 3px solid transparent;
  background-clip: content-box;
}

// /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  $base-color: #758CA5;
  background: darken( $base-color, 10% );
  border: 3px solid transparent;
  background-clip: content-box;
}
