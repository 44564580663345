@import '../../../Utils/Styles/colors.scss';

.timeline-card {
  background-color: $backgroundSecondaryColor;
  width: 100%;
  height: fit-content;
  margin: 24px 0 32px;
  min-width: 800px;
  overflow-x: auto;

  &.administrative {
    .step-status-circle.delivered {
      background-color: $stellantisBlue;
    }

    .step-line .line .timeline-line.delivered {
      stroke: $stellantisBlue;
    }
    .step-name .delivered {
      color: $stellantisBlue;
      font-weight: 700;
    }

    .stepDate-status .delivered {
      font-weight: 700;
    }
  }

  .timeline-grid {
    width: fit-content;
    padding: 24px 40px 24px 40px;
    background-color: $backgroundSecondaryColor;
    display: grid;
    justify-items: center;
    grid-auto-flow: column;
    grid-auto-columns: minmax(28px, 1fr) minmax(60px, 2fr);
    grid-template-rows:
      [step-milestone] 40px
      [step-status] 70px
      [step-circle] 40px
      [step-name] max-content
      [step-date-status] max-content
      [step-date] max-content;
  }
}

.invoiceDownload {
  min-width: 1200px;
  margin: auto;
  max-width: 1500px;
}

@media only screen and (max-width: 812px) {
  .timeline-client .timeline-card {
    min-width: unset;
    height: unset;
    .timeline-grid {
      grid-template-columns:
        [step-date-status] 90px
        [step-circle] 80px
        [step-name] 90px;

      grid-template-rows: unset;
      grid-auto-flow: row;
      row-gap: 24px;
      grid-auto-rows: 80px;

      .step-name {
        flex-direction: column;
        align-items: unset;
      }
    }
  }
}
