@import '../../../Utils/Styles/colors.scss';

.parametersModelsVersions {
  .parametersModelsVersions-listItem.item-child {
    > td {
      background-color: $blueHover;
    }
    .parameters-steps-version-padding {
      padding-left: 24px;
    }
  }
  .parametersModelsVersions-list {
    border-radius: 4px;
  }

  .parametersModelsVersions-listItem {
    > td {
      display: flex;
      align-items: center;
    }
    .origin-text {
      text-transform: capitalize;
    }

    .parametersModelsVersions-listItem-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      > svg {
        fill: $actionPrimaryColor;
        margin-left: 16px;
        cursor: pointer;
        width: 16px;
        height: 16px;
      }

      .parametersModelsVersions-addButton {
        display: flex;
        flex-direction: row;
        align-items: center;

        > p {
          margin-left: 8px;
          text-transform: none;
          color: $actionPrimaryColor;
        }

        > svg {
          fill: $actionPrimaryColor;
          width: 16px;
          height: 16px;
        }
      }

      .parametersModelsVersions-addButton.disabled {
        > p {
          color: #BEBEBE;
        }
        > svg {
          fill: #BEBEBE;
        }
      }
    }

    .parametersModelsVersions-listItem-buttons.disabled {
      > svg {
        fill: #BEBEBE;
        cursor: auto;
      }
    }
  }
}
