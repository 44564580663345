@import '../../../Utils/Styles/colors.scss';

.fleetButton {
  width: max-content;
  height: 28px;
  z-index: 2;
  border-radius: 5px;
  align-self: center;
  border: 1px solid $textSecondaryColor;
  color: $textSecondaryColor;

  &:hover {
    background-color: white;
    cursor: pointer;
  }

  span {
    color: $textSecondaryColor
  }

  .fleetIcon {
    margin-right: 8px;
    fill: $textSecondaryColor
  }
}

.fleetButton-popoverContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 250px;

  .fleetButton-popoverContent-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    > span {
      color: $textSecondaryColor;
    }
  }

  .fleetButton-popoverContent-fleetInformation {
    display: grid;
    grid-template:
    'a b'
    'c c';
    column-gap: 24px;
    row-gap: 12px;

    :nth-child(1) {
      grid-area: a;
    }
    :nth-child(2) {
      grid-area: b;
    }
    :nth-child(3) {
      grid-area: c;
    }
  }
}

.popoverClass {
  box-shadow: none;
}
