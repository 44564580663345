article.faq__question_and_answer {
  color: #5E5E5E;
  margin-bottom: 40px;

  .faq__question {
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .faq__answer {
    padding-left: 34px;

    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 16px;

      .badge {
        width: fit-content;
        height: 21px;
        margin-left: 8px;
        padding: 8px 8px;
        border-radius: 4px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
      }
      .badge-late {
        width: 56px;
        background-color: rgba(#FD003E, 0.15);
        color: #FD003E;
      }

      .badge-inRisk {
        width: 85px;
        background-color: rgba(#DD9304, 0.15);
        color: #DD9304;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
