@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.modelVersionForecast {
  min-width: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .page-header {
    border-radius: 4px;
  }

  .modelVersionForecast-content {
    display: flex;
    padding: 24px;
    overflow: hidden;

    .sideBar {
      width: 350px;
      overflow-y: auto;
      margin-right: 16px;
      background-color: $backgroundSecondaryColor;
      border-radius: 4px;
      padding: 24px;

      .transitTime-warning {
        display: flex;
        padding: 8px;
        border-radius: 4px;
        background: #FCD6DF;
        margin-bottom: 16px;
        width: 229px;

        svg {
          margin-right: 8px;
          fill: $errorColor;
          width: 16px;
        }

        p {
          @extend .font-small2;
          color: $errorColor;
          width: 190px;
        }
      }
      .sideBarTitle {
        @extend .font-initials;
        margin-bottom: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $stellantisBlue;
      }
      .sideBarSubTitle {
        @extend .font-small2;
        margin-bottom: 16px;
        color: $textBlackColor;
      }
      .sideBarText {
        line-height: 12px;
        margin-bottom: 24px;
        .subTitle {
          color: $textSecondaryColor;
          font-size: 10px;
          font-weight: bold;
          text-align:start;
          text-transform: uppercase;
          letter-spacing: 0.2px;
          line-height: 11px;
          margin-bottom: 4px;
        }
        .text {
          @extend .font-small2;
          white-space: break-spaces;
          color: $textBlackColor;

          > ul {
            padding-left: 24px;
          }
        }
      }
    }

    .modelVersionForecast-mainContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 722px;
      .tabs-selector {
        margin-top: 0;
        margin-bottom: 9px;
      }
      .modelVersionForecast-tableContent {
        padding: 0px;
        background-color: $backgroundSecondaryColor;
        border-radius: 4px;
        height: calc(100% - 29px);
        display: flex;
        flex-direction: column;

        .modelVersionForecast-filter {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 24px 32px;
          height: 80px;
          justify-content: space-between;

          > h6 {
            margin-right: 16px;
          }

          .modelVersionForecast-buttons{
            display: flex;
            flex-direction: row;
            .upload{
              margin-left: 16px;
            }
          }
        }

        .modelVersionForecast-filter.filterWithExport {
          justify-content: space-between;
          .export-buttom {
            margin-right: 16px;
          }
          .cancelingButton {
            margin-right: 16px;
            button {
              height: 34px;
            }
          }
          .rightSideTransitTime {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
          .editingButtons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }

        .modelVersionForecast-tableWrapper {
          overflow: auto;
          .modelVersionForecast-table {
            .list-content {
              grid-template-columns: 1fr 1fr 0.5fr;
              margin-top: 0;
              padding: 0px;

              .cityWithPadding {
                padding-left: 20px;
              }

              td {
                border-bottom: 1px solid #E5EAEE;
                display: flex;
                align-items: center;
              }

              th:last-of-type {
                justify-content: flex-end;
                padding-right: 32px;
              }

              .header-item {
                height: 48px;
                position: sticky;
                top: 0;
                background-color: $backgroundSecondaryColor;
              }

              td:last-of-type {
                justify-content: flex-end;
                text-align: end;
              }

              .parameters-input-wrapper {
                text-align: center;
                display: flex;
                width: fit-content;
              }
              .modelVersionForecast-model {
                display: flex;
                flex-direction: column;
                .modelName {
                  text-overflow: ellipsis;
                  text-transform: capitalize;
                  overflow: hidden;
                  width: 100%;
                  margin-bottom: 4px;
                }
                .modelCode {
                  display: flex;
                  flex-direction: row;
                  align-items: baseline;

                  span{
                    color: $textSecondaryColor;
                    font-size: 12px;
                    line-height: 12px;
                  }
                }
              }
            }
          }

          .modelVersionForecast-table.tab1 {
            .list-content {
              grid-template-columns: 0.1fr 0.5fr 0.5fr 0.5fr 0.5fr;
              td:last-of-type,th:last-of-type {
                justify-content: flex-start;
              }
            }

            &.BR{
              .list-content {
                grid-template-columns: 0.1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
                td:last-of-type,th:last-of-type {
                  justify-content: flex-start;
                }
              }
            }
          }
          .modelVersionForecast-table.tab0.customercarebr {
            .list-content {
              grid-template-columns: 25% 30% 25% 20%;
            }
          }
        }

        .skeleton-item {
          background-color: $backgroundPrimaryColor;
          margin: 32px 12px;
          padding: 16px;
          width: auto;
        }

        .modelVersionForecast-error {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: auto;
          padding: 32px 0;

          > span {
            margin-top: 24px;
            margin-bottom: 24px;
            white-space: pre-wrap;
            text-align: center;
          }
        }
      }
    }
  }
}
