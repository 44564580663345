

.concessionaria {
  display: flex;
  flex-direction: row;

  > div ~ div {
    margin-left: 16px;
  }

  .tipo {
    color: #5E5E5E;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    flex-direction: row;
    display: inline-flex;
    padding-bottom:8px;
  }

  .endereco {
    > a {
      text-decoration-color: #5E5E5E;
    }
  }

  .contato {
    display: flex;
    > a {
      display: flex;
      text-decoration-color: #5E5E5E;
    }
  }

  .code {
    > a {
      text-decoration-color: #5E5E5E;
    }
  }

  .nome {
    color: #758CA5;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 4px;
    font-weight: 500;
    letter-spacing: 0.2px;

    > a {
      text-decoration-color: #5E5E5E;
    }


    .nomeConcessionaria {
      color: #758CA5;
      text-transform: capitalize;

    }
    .municipioConcessionaria {
      color: #758CA5;
      text-transform: capitalize;
    }

    .estadoConcessionaria {
      letter-spacing: 0.2px;
      color: #758CA5;
      text-transform: capitalize;
    }
  }

    .enderecoConcessionaria {
      letter-spacing: 0.2px;
      color:#758CA5;
      font-size: 12px;
      text-transform: capitalize;
    }

    .contatoConcessionaria {
      color:#758CA5;
      font-size: 12px;
    }

    .codeDealer {
      color:#758CA5;
      font-size: 12px;
    }

  .dot{
    color: #758CA5;
    font-weight: 900;
  }
}
