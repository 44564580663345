@import '../../../Utils/Styles/colors.scss';

.dealerInformation {
  display: flex;
  flex-direction: column;
  font-family: 'Helvetica Neue', sans-serif;

  .dealerLabel {
    margin-bottom: 2px;
  }

  .dealerInformation {
    margin-bottom: 2px;
    text-transform: capitalize;
    color: $textSecondaryColor;
  }

  .dealerId {
    color: $textSecondaryColor;
  }
}
