@import "../../../Common/Assets/Styles/variables.scss";
@import "../../../Utils/Styles/fonts.scss";
@import "../../../Utils/Styles/colors.scss";

.commercialOrderDetail {
  display: inline-block;
  overflow: hidden;

  .status {
    top: 88px;
    left: 40px;
    height: 21px;
    width: 90px;
    border-radius: 3px;
    overflow: hidden;
  }

  .concesisonaria-pedido {
    padding-top: 20px;
    padding-bottom: 40px;

    .concessionaria-pedido-skeleton-wrapper {
      display: flex;
      flex-direction: row;

      .emptyIcon {
        width: 32px;
        height: 32px;

        margin-right: 24px;
      }

      .delaerTitle-skeleton-wrapper {
        display: flex;
        flex-direction: column;
      }

      .dealerTitle-empty {
        width: 231px;
        height: 13px;
        margin-bottom: 12px;
      }

      .dealerSubTitle-empty {
        width: 90px;
        height: 8px;
      }
    }
  }

  .concesisonaria-pedido[class*="skeleton"] {
    padding-bottom: 0px;
    background-color: #ffffff;
  }

  .dados-cliente {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
  }

  .status-pedido {
    margin-bottom: 10px;
  }

  .status-pedido[class*="skeleton"] {
    width: 86px;
    height: 21px;
  }

  .titulo-pedido {
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
  }

  .titulo-pedido h1 {
    font-size: 26px;
    line-height: 26px;
    width: fit-content;
  }

  .fiat-logo {
    height: 25px;
    width: 34px;
    margin-right: 36px;
  }

  .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
  }

  .titulo-pedido[class*="skeleton"] {
    width: 289px;
    height: 32px;
  }

  .dados-cliente-pedido {
    margin-top: 36px;
    margin-bottom: 32px;
  }

  .dados-cliente-pedido[class*="skeleton"] {
    margin-top: 12px;
    width: 203px;
    height: 32px;
  }

  .concessionaria-entrega {
    margin-top: 32px;
    margin-bottom: 40px;
    width: fit-content;
    .concessionaria {
      width: fit-content;
    }
  }

  .concessionaria-entrega[class*="skeleton"] {
    margin-top: 24px;
    width: 171px;
    height: 13px;
  }

  .detalhes-text {
    font-size: 12px;
  }

  .dataSkeleton {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .dataTitle {
      width: 231px;
      height: 13px;
    }

    .dataSubTitle {
      margin-top: 8px;
      width: 193px;
      height: 10px;
    }

    .dataSubSubTitle {
      margin-top: 8px;
      width: 63px;
      height: 10px;
    }
  }

  .contactDealer {
    display: flex;
    align-items: center;
    svg {
      fill: $textSecondaryColor;
    }
    span {
      @extend .font-small2;
      margin-left: 14px;
      color: $textSecondaryColor;
    }
  }

  .surroundingBodyDiv {
    max-width: 100%;

    .closed {
      max-height: 0;
      transition: max-height 0.5s ease-out;
    }

    .open {
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    }
  }

  .resizeButton-mobile {
    margin: auto;
    width: 38px;
    height: 38px;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 16px);
  }

  .pedidoIndustrial-downloadInvoiceDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 372px;
    height: 65px;
    left: 0px;
    top: 310px;
    background: #f5f8ff;
    border-radius: 4px;
    margin-left: 0px;

    .pedidoIndustrial-downloadInvoiceDiv-text {
      font-size: 12px;
      text-align: left;
      color: $textSecondaryColor;
    }

    .pedidoIndustrial-downloadInvoiceDiv-button {
      font-size: 12px;
      width: 160px;
      height: 31px;
    }
  }

  .commercialOrderSelector {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
