@import '../../../Utils/Styles/colors.scss';

.errorBoundary-Page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .errorBoundary-image {
    width: 50%;
    height: 100%;
    object-fit: cover;
    background-color: #EBF3FA;
  }

  .errorBoundary-content {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .errorBoundary-contentWrapper {
      width: 50%;
      display: flex;
      flex-direction: column;

      .errorBoundary-easyLogo {
        margin-bottom: 16px;
      }

      .errorBoundary-title {
        margin-bottom: 16px;
      }

      .errorBoundary-text {
        color: $textBlackColor;
        margin-bottom: 24px;
      }

      .errorBoundary-buttons {
        button ~ button {
          margin-left: 16px;
        }
      }
    }
  }
}
