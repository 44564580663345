.industrialOrdersSlider-bulletList {
  > li {
    width: fit-content;
    height: fit-content;
  }
}

.industrialOrdersSlider-slide {
  margin: auto;
}
