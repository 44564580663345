@import "../../../Utils/Styles/colors.scss";

.comercialBody {
  background-color: #eceef2;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.7fr 2fr;

  .detailsComercialTitle {
    margin-left: 40px;
    margin-top: 40px;
    cursor: pointer;
  }

  .detailsComercialTitle[class*="skeleton"] {
    width: 240px;
    height: 16px;
  }

  .skeleton {
    border-radius: 5px;
    background-color: rgba(229, 234, 238, 0.3);
  }

  .comercialTitle {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-decoration: none;
    padding-left: 12px;
  }

  .commercialDetails {
    background-color: #ffffff;
    padding: 40px;
    height: 100%;

    .commercialOrderDetail {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .phoneContact {
        justify-content: center;
        margin: 0 auto 0 auto;
      }
    }
  }

  .listPedidosIndustriais {
    background-color: #ffffff;
    padding-left: 40px;
    padding-top: 40px;
    margin-bottom: 24px;
  }

  .pedidoComercial {
    background-color: #ffffff;
    width: 40%;
    min-width: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  #industrialOrdersList {
    padding-left: 27px;
  }

  #industrialOrdersWrapper {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    min-width: 800px;
    max-width: 1200px;

    .piCard {
      width: auto;
      border-radius: 4px;

      .details {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  #industrialOrdersWrapperEmpty {
    @extend #industrialOrdersWrapper;
    background-color: #ffffff;
  }

  .timeline-body {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 32px;
    min-width: 800px;
    max-width: 1200px;
    #timelineWrapper {
      width: 100%;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      margin: 0px 0 16px;

      .clientLegalTerm {
        padding: 0 32px 16px 32px;
        text-align: justify;
      }

      .timeline-card {
        margin: 0px 0px 16px;
        height: unset;
        border-radius: 4px;
        justify-content: center;
        overflow-y: hidden;
        overflow-x: auto;

        .timeline-grid {
          margin: 0;
          grid-auto-columns: minmax(40px, 1fr) minmax(50px, 2fr);
          min-width: 730px;
          padding-bottom: 0px;
        }
      }
    }
  }

  #timelineWrapperEmpty {
    width: 100%;
    min-width: 800px;
    max-width: 1200px;
    height: calc(100% - 420px);
    background-color: #ffffff;
  }

  #siebelWrapper {
    width: 777px;
    margin: auto;
    margin-bottom: 24px;
  }

  .industrialOrder {
    width: 100%;
    overflow-y: auto;
  }

  #industrialDetailsDiv {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 0px 24px;

    #topLeftElement {
      margin-top: 40px;
      margin-left: 40px;
      width: 86px;
      height: 21px;
    }

    #carNameEmpty {
      margin-top: 24px;
      margin-left: 40px;
      width: 409px;
      height: 28px;
    }

    #carSubTitle {
      margin-left: 40px;
      margin-top: 24px;
      width: 172px;
      height: 13px;
    }

    #carDetailsWrapperEmpty {
      display: flex;
      flex-direction: column;
      margin-top: 60px;
      margin-left: 40px;

      .columnEmptyWrapper {
        display: flex;
        flex-direction: column;
      }

      .carDetailRow {
        display: flex;
        flex-direction: row;

        .verySmallTitleEmpty {
          width: 25px;
          height: 8px;
          margin-top: 8px;
        }

        .smallTitleEmpty {
          width: 37px;
          height: 13px;
          margin-right: 48px;
        }

        .largeTitleEmpty {
          width: 129px;
          height: 13px;
        }
      }
    }

    .skeleton {
      background-color: rgba(229, 234, 238, 0.3);
      border-radius: 5px;
    }

    .longDistance {
      margin-top: 24px;
      margin-right: 41px;
    }

    .topDistance {
      margin-top: 8px;
    }

    .legalTerm-link {
      color: #657DD9;
      padding-left: 8px;
    }
  }

  .siebelComponent {
    background-color: #ffffff;
  }

  #noIndustrialOrders {
    background-color: #ffffff;
    width: 100%;
    display: flex;

    #noIndustrialOrdersCenterWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      #noIndustrialOrdersMessage {
        margin-top: 24px;
        color: #758ca5;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
    }
  }
}


.employeeWelcomeModal-wrapper {
  .baseModal-content {
    width: 90%;
    max-width: 460px;
    min-width: 330px;
  }
  .employeeWelcomeModal {
    position: relative;

    .employeeWelcomeModal-closeIcon {
      width: 12px;
      height: 12px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      margin: 16px 16px 0px 0px ;
      z-index: 1;
    }
    .employeeWelcomeModal-image {
      width: 100%;
      height: 300px;
      display: flex;
      border-radius: 5px 5px 0 0;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, rgba(12, 28, 45, 0.8), rgba(12, 28, 45, 0.8)), url("../../../Common/Assets/fca-betim.jpg");
      object-fit: cover;
      background-size: cover;

      .feather-logo {
        height: 30px;
        width: 150px;
      }

      .divisao {
        height: 30px;
        margin: 0 30px 0 30px;
      }

      .easy-tracking {
        width: 150px;
        font-size: 16px;
      }
    }

    .employeeWelcomeModal-text {
      padding: 32px;

      h5 {
        text-align: center;
        margin-bottom: 16px;
      }

      span {
        display: block;
        white-space: pre-wrap;
        text-align: justify;
        color: $textBlackColor;
      }
    }
  }
}

@media only screen and (max-height: 812px) {
  .employeeWelcomeModal-wrapper .baseModal-content .employeeWelcomeModal .employeeWelcomeModal-image {
    height: 150px;
  }
}

@media only screen and (max-width: 1562px) and (min-width: 812px) {
  .comercialBody {
    overflow-x: auto;
    .industrialOrder {
      width: fit-content;
      #industrialDetailsDiv {
        width: fit-content;
      }
      .timeline-body {
        width: fit-content;
        #timelineWrapper {
          overflow-x: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 812px) {
  .private-pages .client-content {
    flex-direction: column;

    .client-menu {
      width: 100%;
      height: 56px;

      .menu {
        width: 100%;
        height: 56px;
        flex-direction: row;
        overflow-y: hidden;

        .endMenuItems {
          flex-direction: row;
          margin-left: auto;
        }
      }
    }
  }

  .comercialBody {
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-width: 364px;
    overflow-x: unset;

    #timelineWrapperEmpty {
      display: none;
    }

    #siebelWrapperEmpty {
      display: none;
    }

    .commercialDetails {
      height: fit-content;
      padding: 24px 24px 0px 24px;
      position: relative;
      .commercialOrderDetail .phoneContact {
        margin-bottom: 24px;
      }
    }
    .industrialOrder {
      flex-direction: column;
      overflow-y: hidden;
      width: fit-content;
      min-width: 100%;

      #industrialDetailsDiv {
        margin: 0px 16px;
        width: auto;
        padding: 0;
      }
    }

    .pedidoComercial {
      min-width: 440px;
      width: 100%;
      height: fit-content;
      position: relative;
      background-color: transparent;
      padding-bottom: 20px;
    }
    .timeline-body {
      width: 100%;
      min-width: 408px;
      margin-bottom: 48px;
      #timelineWrapper {
        width: 100%;
        max-width: unset;

        .clientLegalTerm {
          padding: 0 16px 16px 16px;
        }

        .timeline-client .timeline-card .timeline-grid {
          margin: auto;
          width: fit-content;
        }
        .timeline-card {
          display: flex;
          .timeline-grid {
            min-width: 330px;
          }
        }
      }
    }

    .pedidoIndustrial-downloadInvoiceDiv {
      display: grid;
      grid-template-areas:
      "nf button"
      "text button";
      grid-template-columns: 1fr auto;
      margin-top: 0;
      padding: 16px;
      margin-bottom: 48px;

      .pedidoIndustrial-downloadInvoiceIcon {
        grid-area: nf;
      }

      .pedidoIndustrial-downloadInvoiceDiv-text{
        grid-area: text;
        font-size: 12px;
        text-align: left;
        color: $textSecondaryColor;
        margin: 4px 0 0 0;
      }

      .pedidoIndustrial-downloadInvoiceDiv-button{
        grid-area: button;
        font-size: 12px;
        width: 100px;
        height: 31px;
        justify-self: flex-end;
      }
    }
  }

}
