@import "../../../Common/Assets/Styles/variables.scss";
@import "../../../Utils/Styles/colors.scss";

.menu {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  width: 56px;
  background-color: white;
  padding-bottom: 12px;
}

.menuLogoDiv {
  height: 56px;
  width: 56px;
  background-color: $stellantisBlue;
  display: inline-flex;
  flex-shrink: 0;
  fill: white;

  svg {
    width: 100%;
    height: 100%;
    padding: 8px;
  }
}

.menuIconDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  span {
    text-align: center;
    margin-top: 4px;
    color: $stellantisBlue;
  }
}

.menuIconElement {
  fill: $stellantisBlue;
}

.selectedRectangle {
  height: 40px;
  width: 4px;
  border-radius: 0 4px 4px 0;
  background-color: $stellantisBlue;
  position: absolute;
  top: 16%;
  left: 5px;
}

.menuItem {
  height: 66px;
  width: 56px;
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  transition: padding-left 0.3s ease;
  padding-left: 5px;

  &.settings {
    padding-left: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);

    #logoutIcon svg g g g g {
      fill: #fd003e;
    }
  }
}

.userInfoItem {
  height: 56px;
  width: 56px;
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
}

.endMenuItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: auto;

  .menuItem {
    height: 50px;

    .selectedRectangle {
      top: 12%;
    }
  }

  .notificationButton {
    margin-left: 12px;
    margin-right: auto;
  }
}
