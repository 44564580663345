@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

#simple-popover {
  background-color: white;
  border: 1px solid #c7ced8;
  box-sizing: border-box;
  box-shadow: -1px 8px 23px -6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 4px;
  left: unset !important;
}
.searchDropdown {
  border-radius: 4px;
  border: 1px solid;
  border-color: $textSecondaryColor;
  padding: 8px;
  display: flex;
  align-items: center;
  input,
  input:focus {
    border: none;
    background-color: unset;
    outline: none;
    width: 260px;
  }
  .sortingArrowIcon,
  .closeIcon {
    fill: $textSecondaryColor;
    margin: 4px 4px 4px 12px;
    width: 8px;
    cursor: pointer;
  }
  .closeIcon:hover {
    fill: $errorColor;
  }
  .sortingArrowIcon.true {
    transform: rotate(270deg);
    fill: $textSecondaryColor;
  }
}

.searchDropDownContent {
  max-height: 160px;
  width: 300px;
  overflow: auto;
  .filterOption {
    margin: 0px;
    .filterItem {
      width: 100%;
      text-align: start;
      height: 32px;
      cursor: pointer;
      border: none;
      background-color: $backgroundSecondaryColor;
      span {
        @extend .font-body2;
        margin-left: 16px;
      }
    }
    .filterItem.selected {
      background-color: rgba(245, 248, 255, 0.5);
      span {
        font-weight: 700;
      }
    }
    .filterItem:hover {
      background-color: rgba(245, 248, 255, 1);
    }
  }
}
