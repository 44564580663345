@import "../../../Utils/Styles/colors.scss";

.macroAreaUser-modal {
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .textComponent {
    height: 100%;
    width: 1200px;
    display: flex;
    flex-direction: column;
    background-color: rgba(12,28,45,0.8);
    border-radius: 4px 4px 0 0;
  }

  .title-box {
    display: inline-flex;
    height: 100%;
    width: 220px;
  }

  .fcaIconEasy {
    display: flex;
    flex-direction: row;
    width: 80%;
    min-width: 500px;
    fill: white;
    justify-content: space-around;
    align-items: center;
    margin-top: 70px;
    margin-left: 50px;
  }

  .logo-stellantis {
    display: inline-flex;
    height: 34px;
    width: 164px;
  }

  .easy-tracking-text {
    height: 26px;
    width: 230px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 31px;
    margin: auto 0;
  }

  .macroAreaUser-modal-content {
    padding: 32px;
  }

  .background {
    width: 460px;
    height: 175px;
    display: flex;
    background: url("../../../Common/Assets/fca-betim.jpg") no-repeat right center;
    background-size: cover;
    border-radius: 4px 4px 0 0;
  }

  .divisao-logo {
    height: 56px;
    width: 1px;
    background-color: #FFFFFF;
    margin: 0 24px 0 24px;
  }

  .title-modal {
    margin: auto auto 16px auto;
    text-align: center;
    width: 200px;
  }

  .text-modal {
    text-align: justify;
    margin-bottom: 32px;
    > p {
      text-indent: 3ch;
    }
  }

  .macroAreaUser-items {
    margin: 0px 0px 32px 24px;
    > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .checkbox-macroArea {
    display: flex;
    align-items: center;

    .checkbox-description {
      font-size: 13px;
      text-transform: uppercase;
      margin-left: 16px;
    }

    .checkbox-item {
      color: #657DD9;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .macroAreaUser-buttons {
    display: flex;
    justify-content: space-around;
  }

  .macroAreaUser-buttons-decline {
    background: none;
    outline: none;
    border: none;
    color: $textBlackColor;
    cursor: pointer;
    font-weight: bold;
  }
}
