.statusDetails {
  margin: auto;
  margin-right: 0px;
  margin-bottom: 32px;
  display: inline-flex;
  flex-shrink: 0;
  height: auto;
  width: 405px;
  border-radius: 3px;
  background-color: rgba(253,0,62,0.15);
  color: #FD003E;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

.statusDetails > .texts {
  display: inline-block;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: center;
}

.statusDetails > .texts > .text-alert{
  margin: 0px;
  text-align: left;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
}

.statusDetails > .texts > .delayReasons > .delayReasonsList > ul > li.delayReasons-text {
  color: #FD003E;
  font-size: 12px;
  font-style: italic;
  line-height: 20px;
  text-align: left;
  font-weight: normal;
}

#lateDays > .texts > .delayReasons {
  line-height: 12px;
}

.alert-icon {
  align-self: center;
  padding-left: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
}

#inDay {
  border-radius: 3px;
  background-color: rgba(117,140,165,0.15);
  color: #758CA5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

#inRisk{
  border-radius: 3px;
  background-color: rgba(255,184,0,0.15);
  color: #DD9304;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.17px;
}

.delayReasons {
  margin-top: 4px;
  .delayReasons-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .delayReasonsList > ul {
    margin: 0px 8px;
    padding: unset;
    list-style-type: "- ";
  }
}
