@import "../../../Common/Assets/Styles/variables.scss";

.page-header {
  min-width: max-content;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 24px 32px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.page-header .subtitle {
  color: #7a7f81;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.page-header .rightSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .unregisteredVersionsWarning {
    margin-right: 16px;
  }
}

.page-header > .header-top {
  display: inline-flex;
  padding-bottom: 9px;
}

.header-top > .title {
  overflow: hidden;
  color: #0c1c2d;
  margin-right: 24px;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  display: flex;
  align-items: center;
}

.header-top > .right-component {
  margin-left: auto;
  justify-content: space-between;
}

.filter {
  cursor: pointer;
}

.right-component {
  display: flex;
  align-items: center;
}

.inner-button {
  display: flex;
  align-items: center;
}

.arrow-up-icon {
  transform: rotate(180deg);
}

.exportar {
  height: 16px;
  color: #0c1c2d;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin-left: 8px;
  text-transform: capitalize;
  font-family: "Helvetica Neue", sans-serif;
}

.export-dropdown {
  border: none;
  display: flex;
  flex-direction: column;
}
