@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

.orderInformationCard-details {
  width: 100%;
  border-top: 1px solid $borderAndDividerColor;
  padding: 28px 16px 16px 16px;
  display: flex;
  justify-content: space-between;

  > div ~ div {
    margin-left: 16px;
  }

  h5 {
    color: $textPrimaryColor;
  }

  > .details-dealers {
    display: flex;
    flex-direction: column;

    > h5 {
      margin-bottom: 24px;
    }

    > div ~ div {
      margin-top: 16px;
    }
  }

  > .details-vehicle {

    > h5 {
      margin-bottom: 24px;
    }

    .details-vehicle-items {
      display: flex;
      justify-content: space-between;

      > div ~ div {
        margin-left: 24px;
      }

      > div > div ~ div {
        margin-top: 16px;
      }
    }
  }

  > .details-vehicleImage {
    img {
      width: 500px;
      height: unset;
    }
  }
}
