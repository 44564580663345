@import '../../../Utils/Styles/colors.scss';

.tab-button-wrapper {
  display: contents;
  .tab-item {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .active {
    border-bottom: 2px solid $actionPrimaryColor;
    color: $actionPrimaryColor;
  }

  .inactive {
    border-bottom: 2px solid rgba(0,0,0,0.05);
    color: $textPrimaryColor;
  }

  .disabled {
    color: $textInactiveColor;
    cursor: default;
  }
}

