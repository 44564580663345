@import '../../../Utils/Styles/colors.scss';

.search-component {
  display: flex;
  align-items: flex-end;
  width: fit-content;
}

.searchInput-endAdornment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .searchInput-separator {
    margin: 0px 8px;
    height: 15px;
    width: 0.3px;
    background-color: $textInactiveColor;
  }
}
