.export-options-pda{
  padding: 16px;

  &--filters{
    display: grid;
    grid-template-columns: 160px 100px 100px;
    gap: 16px;

    margin-bottom: 16px;

    &--field{
      & p {
        padding-bottom: 8px;
        font-size: 16px;
      }
    }
  }
  &--buttons{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
