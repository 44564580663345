@import '../../../Utils/Styles/colors.scss';

.stepMilestone {
  z-index: 1;
  position: relative;
  background: none;
  height: 135px;
  align-content: center;

  .stepMilestone-line {
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .milestone {
    z-index: 2;
    position: absolute;
    background-color: $backgroundSecondaryColor;
    transform: translate(-50%, 0);
    padding: 3px;
    text-align: center;
  }
}