.dashboardPageOrders {
  min-width: 100%;
}

.dashboardPageOrders-tags {
  margin: 0px 24px;
}

.dashboardPageOrders-content {
  padding: 0px 24px;
}

.macroAreasHistory {
  width: 100%;
  height: 100%;
  padding: 24px 32px;
  background-color: white;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 4px;

  min-width: 700px;
  min-height: 600px;

  .macroAreasHistory-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    > h2 {
      color: #0c1c2d;
      font-size: 28px;
      font-weight: bold;
      line-height: 29px;
      margin-bottom: 12px;
      margin-top: 12px;
    }

    .macroAreasHistory-selectorWrapper {
      display: flex;
      flex-direction: row;
      height: 50px;
      align-items: center;

      .macroAreasHistory-loader {
        transform: scale(0.5);
      }

      .macroAreasHistory-select {
        width: 200px;
        margin-right: 30px;
      }
    }
  }

  .macroAreasHistory-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    grid-row-gap: 32px;
    grid-column-gap: 32px;
  }

  .macroAreasHistory-error {
    width: 100%;
    height: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.macroAreaHistory-skeleton-graph {
  width: 90%;
  height: 300px;
  padding: 24px;
  margin: auto;
  margin-top: 16px;
  border-radius: 5px;
  background-color: rgba(229,234,238,0.3);
}

.dashboard-filters {
  display: flex;
  flex-direction: row;

  div ~ div {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 1442px) {
  .resume-commercial-orders {
    flex-direction: column;
    .section-kpi-csi {
      margin-left: 0px;
    }
  }
}
