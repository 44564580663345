@import "../../../Utils/Styles/colors.scss";

.delayNotificationsIcon {
  outline: none;
  border: none;
  background: none;
  position: relative;

  > svg {
    fill: $stellantisBlue;
    margin: auto;
  }

  .newNotifications {
    border-radius: 50%;
    background-color: $errorColor;
    width: 12px;
    height: 12px;
    font-size: 9px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    right: 15px;
    top: 15px;
  }
}
