.login-component{
  width: 100%;
  display: flex;
  background: url("../../../Common/Assets/fca-betim.jpg") no-repeat right center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.loading-logo{
  background-color: rgba(12,28,45,0.8);
  margin: auto;
	height: 41px;
  width: 124px;
}

.entry-background{
  height: 100%;
  width: 100%;
  background-color:white;
  display: inline-flex;
}

.feather-logo {
  display: inline-flex;
	height: 100%;
  width: 220px;
}

.title-box {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  #fcaIconEasy {
    display: flex;
    flex-direction: row;
    width: 80%;
    min-width: 500px;
    fill: white;
    justify-content: space-between;
    align-items: center;
  }

  .loginAbout {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-self: center;

    span , p {
      color: #FFFFFF;
      text-align: justify;
      white-space: pre-wrap;
    }

    .loginAbout-title {
      margin: 36px 0px 16px 0px;
      text-transform: uppercase;
    }
  }
}

.divisao {
	height: 56px;
	width: 1px;
  background-color: #FFFFFF;
  margin: 0 24px 0 24px;
}

.easy-tracking {
  height: 26px;
  width: 230px;
  color: #FFFFFF;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 31px;
  margin: auto 0;
}

.login-subtitle {
  height: 18px;
  width: 200px;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin: 0px 420px 26px 0;
  margin-left: 90px;
}

.loginSubtitle{
	color: #FFFFFF;
	font-size: 15px;
  line-height: 16px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.login-text {
  height: 100%;
  width: 1200px;
  display: flex;
  flex-direction: column;
  border: 1px solid #979797;
  background-color: rgba(12,28,45,0.8);
}

.info-box {
  margin: 26px 144px 0 72px;
}


.user-entry{
  float: right;
  margin: auto;
  margin-top: 200px;
}


.faca-seu-login {
	height: 27px;
	width: 189px;
	color: #0C1C2D;
	font-size: 27px;
	line-height: 32px;
}

@media only screen and (max-width: 812px) {
  .login-component {
    display: block;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }

  .login-component::-webkit-scrollbar {
    display: none;
  }

  .feather-logo {
    width: 140px;
  }

  .title-box{
    margin: auto 0px;
    padding: 24px 0;
    #fcaIconEasy {
      width: auto;
      margin: auto;
      min-width: unset;
      .easy-tracking {
        width: 160px;
        font-size: 17px;
      }
    }
  }

  .divisao {
    height: 32px;
    width: 0.59px;
    margin: 0 24px 0 24px;
  }

  .login-text {
    width: 100%;
    height: fit-content;
    min-height: 40%;
    min-width: 280px;
  }

  .entry-background{
    height: fit-content;
    min-height: 60%;
    width: 100%;
    min-width: 280px;
  }

  .login-box {
    margin-top: 56px;
    width: 90%;
    overflow: hidden;
  }

  .login-subTitulo {
    margin-bottom: 32px;
  }

}

@media only screen and (max-width: 360px) {

  .feather-logo {
    width: 110px;
  }

  .title-box{
    padding: 24px 0;
    #fcaIconEasy {
      width: auto;
      min-width: unset;
      .easy-tracking {
        width: 137px;
        font-size: 15px;
      }
    }
  }

  .divisao {
    height: 30px;
    margin: 0 16px 0 16px;
  }

  .login-titulo {
    font-size: calc(10vw - 4px);
  }

  .login-subTitulo {
    font-size: 14px;
  }

}

@media only screen and (max-width: 280px) {
  .login-titulo {
    font-size: 24px;
  }
}
