.info-box{
  width: 153px;
  margin: 0px 22px 0px 0px;
}

.text{
  width: 100%;
  margin: auto;
	color: #5E5E5E;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.label{
  width: 100% !important;
	height: 12px;
	color: #758CA5;
	font-size: 10px;
  line-height: 12px;
  padding-top: 2px;
}
