@import '../../../Utils/Styles/colors.scss';

.stepStatus {
  white-space: nowrap;
  text-align: center;
  background: $backgroundSecondaryColor;

  .late {
    color: $errorColor;
  }

  .inRisk {
    color: $warningColor;
  }
}
