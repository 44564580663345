@import '../../../Utils/Styles/colors.scss';

.chassiModal {
  width: 304px;
  height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.chassiDescription {
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.chassiIcon {
  margin-top: 1px;
}

.chassiBodyText {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.chassiNumber {
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 24px;
}

.chassiInfoIcon {
  .chassiInfo-button {
    border-color: $actionPrimaryColor;
    text-transform: capitalize;
    width: 114px;
    height: 32px;
    padding: 12px;

    .font-small2 {
      margin-left: 8px;
      color: $actionPrimaryColor;
    }
  }
}
