@import '../../../Utils/Styles/fonts.scss';

.imagem-boxMobile {
  display: flex;
  flex-direction: column;
}

.car-image {
	height: 210px;
}

.warning {
  @extend .font-small2;
  display: block;
  margin-top: 12px;
  color: $textSecondaryColor;
  text-align: center;
}

@media only screen and (max-width: 812px) {
  .car-image {
    height: 149px;
    width: 261px;
    margin: 32px auto 0px auto;
  }
}
