@import "../../../Utils/Styles/colors.scss";

@mixin absoluteAlignCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin stepStatusColor {
  .late {
    color: $errorColor;
  }

  .onTime {
    color: $actionPrimaryColor;
  }

  .inRisk {
    color: $warningColor;
  }

  .delivered {
    color: $actionPrimaryColor;
  }

  .notStarted {
    color: $textInactiveColor;
  }

  .ongoing {
    color: $actionPrimaryColor;
  }
}

.timeline-grid {
  .step-status {
    z-index: 2;
    height: fit-content;
    background: $backgroundSecondaryColor;
    align-self: center;

    &--logistic {
      z-index: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0px;

      gap: 8px;

      &--line {
        height: 90px;
        margin-bottom: 10px;
        border-left: 2px dashed #657DD9;
      }

      &--card {
        background: #657DD9;
        border-radius: 8px;

        & p {
          padding: 8px 12px;
          white-space: nowrap;
          font-size: 12px;
          color: #fff;
          text-align: center;
        }
      }
    }
  }

  .step-circle {
    z-index: 2;
    background: $backgroundSecondaryColor;
    border-radius: 50%;
    align-self: center;
  }

  .step-line {
    z-index: 1;
    position: relative;
    background: none;
    width: 150%;
    align-content: center;

    .line {
      z-index: 1;
      width: 100%;
      position: absolute;
      // height: 100%;
      @include absoluteAlignCenter();

      .timeline-line.onTime {
        stroke: $actionPrimaryColor;
        stroke-dasharray: 12 8;
      }

      .timeline-line.late {
        stroke: $errorColor;
        stroke-dasharray: 12 8;
      }

      .timeline-line.inRisk {
        stroke: $warningColor;
        stroke-dasharray: 12 8;
      }

      .timeline-line.delivered {
        stroke: $actionPrimaryColor;
        stroke-dasharray: 0;
      }

      .timeline-line.notStarted {
        stroke: $borderAndDividerColor;
        stroke-dasharray: 0;
      }

      .timeline-line.ongoing {
        @extend .onTime;
      }
    }

    .reason-circle {
      z-index: 2;
      position: absolute;
      background: red;
      height: 20px;
      width: 20px;
      @include absoluteAlignCenter();
    }
  }

  .step-name {
    padding-top: 8px;
    min-width: 60px;
    text-align: center;
    align-self: center;
    @include stepStatusColor();
  }

  .stepDate-status {
    padding-top: 12px;
  }
}
