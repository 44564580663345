@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.redirect-box {
  width: 100%;
  max-width: 328px;
  display: flex;
  flex-direction: column;
  border: 0 solid $textBlackColor;
  border-radius: 11px;
  margin: auto;

  button {
    width: fit-content;
    text-transform: uppercase;
  }
}

.redirect-titulo {
  width: 100%;
  margin-bottom: 16px;
  white-space: pre-wrap;
}

.redirect-subTitulo {
  width: 100%;
  margin-bottom: 8px;
  color: $textBlackColor;
  @extend .font-subtitle1;
  line-height: 20px;
}

.redirect-link {
  margin-bottom: 32px;
}
