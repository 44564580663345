@import '../../../Utils/Styles/colors.scss';

@mixin stepStatusColor {
  .late {
    color: $errorColor;
  }

  .onTime {
    color: $actionPrimaryColor;
  }

  .inRisk {
    color: $warningColor;
  }

  .delivered {
    color: $textPrimaryColor;
  }

  .notStarted {
    color: $textInactiveColor;
  }
}

.stepDate-status {
  white-space: nowrap;
  @include stepStatusColor();

  > span {
    font-weight: 500;
  }
}

.stepDate {
  padding-top: 2px;
  white-space: pre-wrap;
  text-align: center;
  @include stepStatusColor();
}

.stepDate.twoDates {
  padding-top: 4.5px;
  line-height: 0;
}

