#responseModal {
  outline: none;
  box-sizing: border-box;
  height: fit-content;
  width: 90%;
  max-width: 570px;
  border: 1px solid rgba(117,140,165,0.5);
  border-radius: 4px;
  background-color: #FFFFFF;

  margin: auto;
  padding: 0 32px;
  display: flex;
  flex-direction: column;

  .response-icon {
    height: 44px;
    width: 46px;
    margin-top: 40px;
    margin-bottom: 24px;
    align-self: center;
  }

  header {
    color: #5E5E5E;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    font-family: inherit;
    text-align: center;
  }

  p.responseModal__subtitle {
    color: #5E5E5E;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    font-family: inherit;
    text-align: center;
    margin-bottom: 32px;
  }

  .responseModal__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  .responseModal__button {
    box-sizing: border-box;
    height: 32px;
    border-radius: 3px;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .responseModal__cancel, .responseModal__close {
    width: 85px;
    border: 1px solid rgba(117,140,165,0.5);
    background-color: #FFFFFF;
    color: #0C1C2D;
    margin-right: 16px;
  }

  .responseModal__retry {
    width: fit-content;
    background-color: #657DD9;
    color: #FFFFFF;

    &:disabled {
      background-color: rgb(150, 175, 228),
    }
  }
}
