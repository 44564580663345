.dateText-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .dateText {
    color: #758ca5;
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
  }
}
