.info-pda {
  border-top: 7px solid #243782;
  z-index: 99;

  padding: 16px;
  max-width: 390px;
  background-color: #fff;
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,.3);
  border-radius: 4px;

  &.overflow{
    max-height: 390px;
    overflow-y: scroll;
  }


  &--head{
    font-weight: 700;
    font-size: 16px;
    color: #5E5E5E;
    margin-bottom: 10px;
  }

  &--divisor {
    margin: 14px 0 !important;
  }

  &--client {
    color: #5E5E5E;
    margin: 10px 0;
  }

  &--list{
    display: flex;
    margin-bottom: 2px;

    p {
      font-size: 14px;
      color: #5E5E5E;
    }

    &--name {
      width: 55%;
      min-width: 55%;
    }

    &--bold{
      font-weight: 700;
      padding-left: 10px;
      overflow-wrap: anywhere;
    }
  }

  a {
    color: #657DD9;
    font-size: 14px;
  }
}
