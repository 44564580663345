.alertsDiv {
  display: flex;
  flex-direction: row;
  margin: auto;
  float: right;

  .iconDiv{
    margin: auto;
    margin-right: 10px;
    float: right;
  }

  #icon{
    fill: #758CA5;
  }

  #orderSwapIcon{
    cursor: pointer;

    :hover {
      #icon{
        fill: #4D7FD4;
      }

    }
  }
}

#swapIndustrialOrdersModal {
  margin: auto;
  min-width: 544px;
  min-height: 272px;
  max-height: 372px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  outline: none;

  background-color: #ffffff;

  #swapIndustrialOrdersModal-header{
    border-bottom: 1px solid #C5C7D1;

    #swapIndustrialOrdersModal-title{
      align-items: center;
      width: 100%;
      padding-left: 32px;
      padding-bottom: 24px;
      margin-top: 24px;
      display: flex;

      #swapIndustrialOrdersModal-icon {
        fill: #758CA5;
        display: flex;
      }

      #swapIndustrialOrdersModal-titleText {
        margin-left: 24px;
        color: #5E5E5E;
        font-size: 14px;
        font-weight: bold;
      }

      #swapIndustrialOrdersModal-closeIcon {
        margin-left: auto;
        margin-bottom: auto;
        margin-right: 32px;
        cursor: pointer;
      }
    }
  }

  #swapIndustrialOrdersModal-swaps {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .swapIndustrialOrdersModal-swap {
      display: inline-flex;
      align-items: center;
      width: 90%;
      margin: auto 32px auto 32px;

      border-bottom: 1px solid #E5EAEE;

      .swapIndustrialOrdersModal-swapContentWrapper {
        display: inline-flex;
        align-items: center;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 16px;
        .swapIndustrialOrdersModal-swapIndustrial {
          color: #758CA5;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
        }

        .swapIndustrialOrdersModal-arrow {
          width: 16px;
          height: 16px;
          margin-left: 32px;
          margin-right: 32px;
        }

        .swapIndustrialOrdersModal-swapComercial {
          color: #4D7FD4;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
}
