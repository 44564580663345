// NORMAL
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-UltLt.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Lt.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Roman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Md.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Bd.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Hv.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Blk.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

//ITALIC
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-UltLtIt.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-LtIt.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-Roman.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-MdIt.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-BdIt.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-HvIt.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeueLTStd-BlkIt.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

