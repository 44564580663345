.managementPage {
  height: 100%;
  display: flex;
  flex-direction: column;

  .managementPage-content {
    display: flex;
    flex-direction: row;
    padding: 24px;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    .delayManagementList {
      width: 100%;
    }

    .delayManagementDetails {
      min-width: 350px;
      overflow-y: auto;
    }
  }
}
