@import '../../../Utils/Styles/colors.scss';

.filterTitle {
	color: $textBlackColor;
	font-weight: bold;
  margin: 16px 0px 16px 28px;
}
.ahorro-filter-fields{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px 24px;

  p {
    font-size: 16px;
  }

  h6 {
    font-weight: 700;

    margin-bottom: 16px;
  }

  &-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  };


  &-fields1{
    display: grid;
    grid-template-columns: 130px 130px 140px;
    gap: 16px;

    .css-1okebmr-indicatorSeparator {
      background-color: #ACBAC9;
    }
  }

  &-fields2{
    display: grid;
    grid-template-columns:  196px 148px 93px 93px;
    gap: 16px;

    & .react-select__control--is-focused {
      border-color: $textBlackColor !important;
    }

    & .react-select-container {
      margin-top: 8px !important;
    }

    .css-1pahdxg-control{
      border-color: #ACBAC9;
    }

    .disabled-ahorro{
      opacity: 0.4;
    }

    & .disabled {
      opacity: 0.4;

      & .disabled-ahorro {
        opacity: 1;
      }
    }
  }
}

.filterHeader {
  display: inline-flex;
}

.closeButton {
  margin: auto 36px auto auto;
  cursor: pointer;
}

.ordersFiltersButtons {
  &.ahorro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  & .ahorro-divisor {
    width: 2px;
    height: 18px;
    margin-left: 10px;
  }
}

.datePickers {
  text-align: center;
}

.filterButton {
  border-top: 1px solid $backgroundPrimaryColor;
  padding: 16px 0px;
  display: flex;
  justify-content: space-evenly;
}

.filterButtonDates {
  padding: 32px 0px 0px 0px;
}

.filterPanels {
  height: fit-content;
  max-height: 460px;
  width: 380px;
  overflow-y: scroll;

  > div {
    border-style: solid;
    border-bottom: 1px solid $backgroundPrimaryColor;
  }

  :last-child {
    border-bottom: none;
  }
}

.filterPanels.AR {
  height: fit-content;
  max-height: 344px;
}

.text-button {
	height: 14px;
	width: 66px;
	color: #FFFFFF;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
  text-align: center;
  text-transform: capitalize !important;
}

.cleanAll-text {
	color: #5E5E5E;
	font-size: 12px;
	font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;
}

.filterDates {
  width: 290px;
  height: 358px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .font-subtitle1 {
    color: $textBlackColor
  }

  > div {
    border: none;
  }

  .filterDateSubtitle {
    margin-top: 4px;
  }
}

.searchInput {
  margin-left: auto;
}
