@import "../../../Common/Assets/Styles/variables.scss";
@import "../../../Utils/Styles/fonts.scss";
@import "../../../Utils/Styles/colors.scss";

.clientCommercialOrders {
  display: grid;
  grid-template-rows: 89px calc(100% - 148px) 59px;
  height: 100%;
  background-color: $disabledBackgroundColor;
  > div {
    box-shadow: inset 8px 0px 10px -4px rgb(0 0 0 / 8%);
  }

  .status {
    top: 88px;
    left: 40px;
    height: 21px;
    width: 90px;
    border-radius: 3px;
    overflow: hidden;
  }

  .status-pedido {
    margin-bottom: 10px;
  }

  .status-pedido[class*="skeleton"] {
    width: 86px;
    height: 21px;
  }

  .titulo-pedido {
    padding: 10px 24px;
    background-color: white;
  }

  .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .detalhes-text {
    font-size: 12px;
  }

  .data-text {
    color: #758ca5;
    font-size: 12px;
    line-height: 14px;
  }

  .commercialOrderDetail-ordersSelector {
    display: grid;
    grid-template-rows:
      minmax(64px, 12.5%) minmax(64px, 12.5%) minmax(64px, 12.5%)
      minmax(64px, 12.5%) minmax(64px, 12.5%) minmax(64px, 12.5%)
      minmax(64px, 12.5%);
    grid-gap: 2%;
    margin: 24px;
    height: calc(100% - 48px);
    overflow: auto;
    box-shadow: none;
    .ordersSelectorItem {
      display: grid;
      grid-template-columns: min-content 1fr 1fr 1fr;
      grid-template-areas: "a b c d";
      box-shadow:
        0px -1px 0px rgba(229, 234, 238, 0.5),
        0px 1px 0px rgba(229, 234, 238, 0.5);
      background-color: white;
      border: 1px solid #f3f4f7;
      box-sizing: border-box;
      border-radius: 4px;

      &:hover {
        background-color: #f5f8ff;
        cursor: pointer;
      }

      .radioButton {
        grid-area: a;
        color: $actionPrimaryColor;
      }

      .orderId {
        grid-area: b;
        align-self: center;
        color: $textBlackColor;
      }

      .model {
        grid-area: c;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-self: center;

        .modelName {
          text-transform: capitalize;
        }

        .modelLabel {
          text-transform: uppercase;
        }
      }

      .tagStatus {
        grid-area: d;
        align-self: center;
        justify-self: flex-end;
        margin-right: 16px;
        .tagOrderStatus {
          width: unset;
          height: fit-content;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .paginationFooter {
    display: flex;
    align-items: center;
    background-color: white;
    > div {
      margin: auto;
      > nav > ul {
        li:first-of-type,
        li:last-of-type {
          button {
            color: #acbac9;
          }
        }
      }
      > nav > ul > li {
        button {
          border: 1px solid #acbac9;
          color: #758ca5;
        }
        .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
          border: 1px solid #657dd9;
          color: #657dd9;
          background-color: unset;
        }
        .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
          background-color: #bebebe;
          color: #d8d8d8;
          border: unset;
        }
        .css-1v2lvtn-MuiPaginationItem-root {
          color: #758ca5;
        }
      }
    }
  }
}

.emptyClientCommercialOrders {
  @extend .clientCommercialOrders;
  .title {
    background-color: white;
    width: 100%;
    .empty {
      background-color: $disabledBackgroundColor;
      margin: 24px;
      width: 88%;
      height: 46%;
    }
  }
  .body {
    @extend .commercialOrderDetail-ordersSelector;
    div {
      background-color: white;
      border-radius: 4px;
    }
  }
  .pagination {
    background-color: white;
    width: 100%;
    .empty {
      background-color: #eceef2;
      margin: 18px;
      width: 91%;
      height: 46%;
    }
  }
}
