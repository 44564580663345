@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';

#releaseNotesModal {
  outline: none;
  box-sizing: border-box;
  max-height: 624px;
  height: 95%;
  max-width: 920px;
  width: 95%;
  border: 1px solid rgba(117,140,165,0.5);
  border-radius: 4px;
  background-color: #FFFFFF;
  position: relative;
  margin: auto;
  padding: 32px 0 8px 40px;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    padding: 0px 0 16px 0;
    position: relative;
  }

  header h1 {
    flex: 1;
    color: #0C1C2D;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
  }

  h2 {
    color:#0C1C2D;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  ul {
    padding-left: 0;
    margin: 0;
  }

  li {
    list-style: none;

    &::before {
      content: "•";
      font-size: 9px;
      vertical-align: middle;
      padding-right: 4px;
    }
  }

  .closeIcon {
    display: flex;
    right: 40px;
    top: 9.5px;
    box-sizing: content-box;
    justify-content: center;
    align-items: inherit;
    position: absolute;
    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;
      #closeCross-groupCross {
        fill: #0C1C2D;
      }
    }
  }

  .releaseNotesModal__body {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 32px;
    margin-right: 4px;
  }

  .releaseNote {
    padding-top: 16px;
    box-shadow: 0px 1px 0px #EBEDF2;
  }

  .releaseDate {
    color: #5E5E5E;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  .releaseDetailedText {
    margin-top: 16px;

    ul {
      margin-top: 8px;
      padding-bottom: 24px;
    }
  }

  .releaseImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 24px;
  }

  .skeleton-item {
    background-color: $backgroundPrimaryColor;
    margin: 32px 12px;
    padding: 16px;
    width: auto;
  }

  .releaseError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    width: 430px;
    height: 179px;
    top: calc(50% - 179px / 2);
    left: calc(50% - 430px / 2);

    h5 {
      margin-top: 8px;
    }

    > span {
      margin-bottom: 24px;
      white-space: pre-wrap;
      text-align: center;
    }

    .releaseEmptyState {
      width: 83px;
      height: 83px;
    }
  }
}
