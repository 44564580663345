@import "../../../Utils/Styles/colors.scss";

.alertIcon {
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 12px;
  margin-top: 2px;

  svg {
    width: 12px;
    height: 12px;
    margin: 4px;
  }
}

.alertIcon.ERROR {
  background-color: rgba($color: $errorColor, $alpha: 0.2);

  svg {
    fill: $errorColor;
  }
}

.alertIcon.WARN {
  background-color: rgba($color: $warningColor, $alpha: 0.2);

  svg {
    fill: $warningColor;
  }
}

.alertIcon.INFO {
  background-color: rgba($color: $actionPrimaryColor, $alpha: 0.2);

  svg {
    fill: $actionPrimaryColor;
  }
}
