@import '../../../Utils/Styles/colors.scss';

.parametersSteps {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .page-header {
    max-height: 112px;
    .editingButtons {
      display: grid;
      grid-template-columns: 82px 1fr;
      grid-gap: 16px;
    }
  }
  .export-buttom {
    margin-right: 16px;
  }
  >.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 24px 24px 24px;
    overflow: hidden;
    .tabs-selector {
      margin: unset;
      padding: 0px 0px 14px 0px;
      span {
        padding-bottom: 13px;
      }
    }
    >.wrapper {
      overflow: auto;
      height: 100%;
      background-color: white;
      border-radius: 4px;
      .stepsTable {
        .list-content {
          margin-top: unset;
          padding: unset;
          > thead {
            > tr {
              > th:first-of-type {
                padding-right: 4px;
              }
              > th:last-child {
                justify-content: flex-start;
              }
              .header-item {
                padding-top: 6px;
                padding-bottom: 6px;
                height: 48px;
                position: sticky;
                top: 0;
                background-color: $backgroundSecondaryColor;
                z-index: 1;
              }
            }
          }
          .ListParametersSteps-item {
            td {
              display: flex;
              align-items: center;
            }
            td:first-of-type {
              padding-right: 4px;
            }
            .parameters-steps-version-padding {
              padding-left: 24px;
            }
          }

          .ListParametersSteps-item.children {
            td {
              background-color: $blueHover;
            }
          }
        }

        .modelVersionForecast-error {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 32px 0;
          >span {
            margin: 24px 0px;
          }
        }
      }
    }
  }
}
