@import '../../../Utils/Styles/colors.scss';

.snackBarTransactions{
  width: 100%;
  .snackBarTransactions-modal{
    background-color:$backgroundSecondaryColor;
    color: black;
    border-bottom: 4px solid;
  }

  .snackBarTransactions-modal.success{
    border-color: $successColor;
  }

  .snackBarTransactions-modal.failure{
    border-color: $errorColor;
  }

  .snackBarTransactions-message{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .snackBarTransactions-icon{
      display: flex;
      margin-right: 10px;
    }
    .snackBarTransactions-button {
      margin-left: 16px;
    }
  }
}
