@import '../../../Utils/Styles/colors.scss';

.featuresModal {
  width: 460px;
  position: relative;

  .featuresModal-closeIcon {
    width: 12px;
    height: 12px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px 16px 0px 0px ;
    z-index: 1;
  }

  .featuresModal-imageWrapper {
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .featuresModal-arrow {
      position: absolute;
      cursor: pointer;
      bottom: -29px;
    }

    .featuresModal-arrow.rotate {
      transform: rotate(180deg);
      right: 0;
    }

    .featuresModal-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }

    .featuresModal-loading-gif {
      height: 279.5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: auto;
        width: 32px;
        height: 32px;
      }
    }
  }

  .featuresModal-content {
    display: flex;
    flex-direction: column;
    padding: 32px;

    .featuresModal-category {
      text-transform: uppercase;
      text-align: center;
      color: $textSecondaryColor;
    }

    .featuresModal-title {
      text-align: center;
    }

    .featuresModal-subtitle {
      text-align: center;
      margin-top: 16px;
      color: $textBlackColor;
    }

    .featuresModal-text {
      margin-top: 16px;
      color: $textBlackColor;
      text-align: justify;
      white-space: pre-line;

      ul {
        padding: 0px 16px;
        margin-top: 8px;
      }
    }

    .featuresModal-dots {
      display: flex;
      justify-self: center;
      align-self: center;
      margin-top: 16px;

      .featuresModal-dotsItem {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $borderAndDividerColor;
        margin: 0px 8px;
      }

      .featuresModal-dotsItem.current {
        background-color: $textSecondaryColor;
      }
    }
  }
}
