@import '../../../Utils/Styles/colors.scss';
@import '../../../Utils/Styles/fonts.scss';
.visionConfiguration-SubMenu {
  display: contents;
  .subMenu {
    background-color: unset;
  }
}
.visionConfiguration {
  height: 100%;
  min-width: 1200px;
  .page-header {
    .editingButtons {
      display: grid;
      grid-template-columns: 82px 1fr;
      grid-gap: 16px;
    }
  }

  .bodyConfiguration {
    display: flex;
    flex-direction: row;
    width: calc(100% - 48px);
    margin: 21px 24px 24px 24px;
    border-radius: 4px;
    height: calc(100% - 184px);

    .sideBar {
      width: 277px;
      overflow-y: auto;
      background-color: $backgroundSecondaryColor;
      border-radius: 4px;
      padding: 24px;
      height: 100%;

      .sideBarTitle {
        @extend .font-initials;
        margin-bottom: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $stellantisBlue;
      }
      .sideBarSubTitle {
        @extend .font-small2;
        margin-bottom: 16px;
        color: $textBlackColor;
      }
      .sideBarText {
        line-height: 12px;
        margin-bottom: 24px;
        .subTitle {
          color: $textSecondaryColor;
          font-size: 10px;
          font-weight: bold;
          text-align:start;
          text-transform: uppercase;
          letter-spacing: 0.2px;
          line-height: 11px;
          margin-bottom: 4px;
        }
        .text {
          @extend .font-small2;
          white-space: break-spaces;
          color: $textBlackColor;

          > ul {
            padding-left: 24px;
          }
        }
      }

      .orderTypeText {
        text-align: start;
        width: 100%;
        span {
          @extend .font-small2;
          color: #898C8D;
          text-transform: none;
        }
      }
    }

    .sideBar.editing {
      width: 306px;
      padding: 0px;
      >div {
        padding: 24px;
      }
      .sideBarHeader {
        border-bottom: 1px solid #e5eaee;
        .sideBarTitle {
          @extend h5;
          color: #0C1C2D;
          text-transform: none;
        }
      }

      .sideBarBody {        
        .modalOneRule {
          .modalOneRuleText {
            display: flex;
            align-items: center;
            margin-left: 6px;
            height: 24px;
            h6 {
              @extend .font-subtitle1;
              color: #5E5E5E;
              margin-left: 4px;
              line-height: 23px;
            }
            svg {
              width: 18px;
              height: 18px;
              fill: #657DD9;
              cursor: pointer;
            }
          }
          .modalOneRuleDescription {
            @extend .font-small1;
            color: #ACBAC9;
          }
        }
        .visible {
          .modalOneRuleText{
            h6 {
              color: #D94F4F;
            }
          }
          .modalOneRuleDescription {
            color: #C9ACAC;
          }
        }
        .modalOneRule ~ .modalOneRule {
          margin-top: 16px;
        }
        .modalOneRule.disabled {
          .modalOneRuleText {
            label > span:first-of-type {
              padding: 0px 12px;
              height: 12px;
            }
            input {
              cursor: default;
            }
            h6 {
              @extend .font-subtitle1;
              color: #BEBEBE;
              margin-left: 4px;
              line-height: 23px;
            }
            svg {
              fill: #BEBEBE;
            }
          }
          .modalOneRuleDescription {
            color: #BEBEBE;
          }
        }
      }      
    }

    .listingBody {
      width: calc(100% - 292px);
      margin-right: 16px;
      .tabs-selector {
        margin-bottom: 8px;
        margin-top: unset;
      }

      .listingBodyHeader {
        background-color: white;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #e5eaee;
        padding: 24px 32px;

        .search-component > div {
          width: 300px;
        }
      }

      .listingWrapper {
        background-color: $backgroundSecondaryColor;
        border-radius: 0px 0px 4px 4px;
        height: calc(100% - 107px);
        overflow: auto;

        .list-content {
          grid-template-columns: 6% 17% 10% 67%;
          margin-top: 0px;
          padding: unset;

          td {
            display: flex;
            align-items: center;
          }

          th:last-of-type {
            justify-content: flex-start;
          }

          .header-item {
            position: sticky;
            top: 0;
            background-color: rgb(255, 255, 255);
            height: 42px;
            border-top: unset;
            z-index: 2;
          }

          .children {
            td {
              background-color: $blueHover;
            }
          }
        }

        #emptyState {
          width: 100%;
          height: 100%;

          display: flex;

          align-content: center;

          #emptyState-content {
            width: fit-content;
            height: fit-content;

            margin: auto;
            display: flex;

            flex-direction: column;

            align-items: center;
          }

          #emptyState-text {
            margin-top: 24px;
            font-weight: 500;
            color: #5e5e5e;
          }
        }
      }
    }
  }

  .bodyConfiguration.editing {
    .listingBody {
      width: calc(100% - 321px);
      .listingWrapper {
        .list-content {
          grid-template-columns: 54px 5% 19% 10% calc(66% - 54px);

          td:first-of-type, th:first-of-type {
            border-right: 1px solid #e5eaee;
            padding: 12px 18px;
            justify-content: center;

            svg {
              width: 18px;
              height: 18px;
              fill: $actionPrimaryColor;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.sideBarToolTip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 116px;
  white-space: pre-wrap;
  text-align: start;
  span {
    @extend .font-small3;
    color: white;
  }
}

.sideBarApi ~ .sideBarText .text ul li:nth-last-child(1) {
  color: #D94F4F;
}