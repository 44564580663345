.item {
  flex-direction: row;
  display: inline-flex;
}

.item > div.typeIcon {
  width: 36px;
  height: 36px;
  margin: auto;
  margin-right: 10px;
  display: inline-block;
}

.item > .clientDataDiv {
  margin: auto;
  display: flex;
  margin-right: 0px;
  flex-direction: column;
  float: left
}

.item > .clientDataDiv > .nome-cliente {
  flex-direction: row;
  display: inline-flex;
}

.item > .clientDataDiv > .doc-cliente {
  height: 14px;
  color: #758CA5;
  font-size: 12px;
  line-height: 14px;
}

.item > .clientDataDiv > .nome-cliente > .pendencia {
  flex-direction: row;
  display: inline-flex;
  float: right;
}
