.input-parameter {
  color: #5E5E5E;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border: none;
  background-color: white;
  font-family: 'Helvetica Neue', sans-serif;
}

.input-parameter:focus {
  outline: none;
}

.input-parameter::placeholder {
	color: #E9EEF1;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}
