.colunaMobileLeft {
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
}

.colunaMobileRight {
  display: inline-flex;
  flex-direction: column;
  width: 190px;
}

.linhaMobile {
  display: inline-flex;
  margin-top: 24px;
  text-transform: capitalize;
  cursor: default;
}

.linhaMobile .label {
  text-transform: uppercase;
}

.detailsMobile-box{
  display: flex;
  justify-content: space-between;

  .linhaMobile:first-of-type {
    margin-top: 0;
  }
}

.detailsMobile-box-dealer{
  display: block;
  width: 368px;
}

.detailsMobile{
  display: flex;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  flex-direction: column;
}

.text-optional{
  width: 100%;
  margin: auto;
	color: #5E5E5E;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.label-optional{
  width: 100% !important;
	height: 12px;
	color: #758CA5;
	font-size: 10px;
  line-height: 12px;
  padding-top: 2px;
  text-transform: uppercase;
}
