@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.delayedOrders {
  .delayedOrdersWarning {
    display: flex;
    padding: 8px;
    border-radius: 4px;
    background-color: #fff5d9;
    margin-right: 16px;
    p {
      color: $warningColor;
      @extend .font-small2;
    }
    svg {
      margin-right: 8px;
      fill: $warningColor;
      vertical-align: middle;
    }

    .expandArrowIcon {
      margin-left: 8px;
      margin-right: 0;
      cursor: pointer;

      #path-expand-arrow {
        fill: $warningColor;
      }
      #path-expand-background {
        fill: none;
      }
    }
  }
}

#delayedOrders-popper {
  margin-top: 8px;
  width: min(340px, 90vw);
  height: auto;
  z-index: 2;
  border-radius: 4px;
  background-color: $backgroundSecondaryColor;
  box-shadow: 0px 0px 2px rgba(85, 87, 112, 0.08), 0px 4px 8px rgba(85, 87, 112, 0.16);

  .delayedOrders-article {
    height: 100%;

    .delayedOrders-section {
      .delayedOrders-header {
        @extend .font-subtitle1;
        padding: 16px;
        box-shadow: inset 0px -1px 0px $borderAndDividerColor;
        color: $textBlackColor;
      }
      .delayedOrders-content {
        overflow-y: auto;
        height: auto;
        max-height: min(400px, 80vh);

        .delayedOrders-dealer {
          padding: 8px 16px;
          box-shadow: inset 0px -1px 0px rgba(237, 237, 237, 0.5);
          .dealer-name {
            @extend .font-small1;
            background-color: transparent;
            border: none;
            outline: none;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            padding: 0;
            margin: 0;
            cursor: pointer;
            color: $textBlackColor;
            transition: 0.3s;
          }
          .quantity-delayed {
            @extend .font-small3;
            color: $textPrimaryColor;
            line-height: 14px;
          }
        }
      }
    }
  }
}
