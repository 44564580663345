#faq {
  padding: 24px 24px 36px;
  width: 100%;
  height: max-content;

  .page-header {
    font-size: 36px;
    font-weight: bold;
    line-height: 44px;
    color: #0C1C2D;
    margin: 0 0 24px 0;
  }

  .faq__questions {
    background-color: white;
    padding: 80px 176px 136px;

    article.faq__question_and_answer:last-of-type {
      margin-bottom: 0;
    }
  }
}
