.content {
  margin-top: 0px;
}

.transitTime {
  .list-header > .header-item {
    margin-left: 0px;
  }

  .list-header > div:first-child{
    margin-left: 10px;
  }

  .list-header > div:last-child {
    text-align: left;
    padding-right: 0;
  }
}
