@import '../../../Utils/Styles/fonts.scss';
@import '../../../Utils/Styles/colors.scss';
.radioFilterPopper {
  .radioHeader {
    padding: 16px 24px;
    display: flex;
    border-bottom: 1px solid #E5EAEE;
    span {
      @extend h6;
      text-transform: capitalize;
    }
  }
  .radioList {
    width: 288px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 422px;
    overflow: auto;
    .radioItem {
      padding: 8px 24px;
      display: flex;
      align-items: center;
      .radioItemText {
        .labelFilter {
          margin-left: 0px;
          span {
            @extend .font-subtitle2;
          }
        }
        .codeTexts {
          .codeSpan {
            @extend .font-small2;
            color: $textSecondaryColor;
          }
          .codeNumberSpan {
            @extend .font-small1;
            color: $textSecondaryColor;
          }
        }
      }
    }
    .radioItem.true{
      align-items: flex-start;
    }
  }
}
