@import "../../../Utils/Styles/colors.scss";

.login-box {
  width: 100%;
  max-width: 452px;
  display: flex;
  flex-direction: column;
  border: 0 solid #0c1c2d;
  border-radius: 11px;
  margin: auto;
  padding: 16px;
}

.buttons-concessionarias {
  padding: 4px 0px; /* Ajuste no padding para diminuir o tamanho dos botões */
  margin-right: 8px; /* Ajuste na margem para diminuir o espaçamento entre os botões */
  font-size: 14px; /* Ajuste no tamanho da fonte do botão */

  span {
    display: flex;
    flex-direction: column;
  }

  .label-conc {
    font-size: 10px; /* Ajuste no tamanho da fonte da label */
    margin-top: -3px; /* Ajuste na margem superior */
  }
}

.login-entry {
  margin-bottom: 20px;
  margin-left: 0px;
  p {
    font-size: 14px;
  }
}

.clientRecaptcha {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.login-label {
  margin-bottom: 10px;
  p {
    font-size: 14px;
  }
}

.user-type-form-div {
  margin-bottom: 16px;
}

.login-titulo {
  width: 100%;
  margin-bottom: 16px;
  white-space: pre-wrap;
}

.login-subTitulo {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 39px;
}

.loginButtonDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .forgotPasswordButton {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 16px;

    .font-subtitle2 {
      font-weight: normal;
    }
  }
}

.loginButtonDiv.button-start {
  justify-content: flex-start;
  gap: 20px;
}

.login {
  height: 12px;
  width: 38px;
  color: #0c1c2d;
  font-size: 12px;
  line-height: 14px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.feather-user {
  height: 20px;
  width: 20px;
  margin-bottom: auto;
}

.input-text {
  width: 100%;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    appearance: textfield;
  }
}

.button {
  text-align: center;
  height: 40px;
  width: 400px;
  border-radius: 2px;
}

.buttonProgress {
  position: absolute;
  margin-top: -12;
  margin-left: -12;
}

.userEntryButtonText {
  height: 14px;
  width: auto;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
}

.search-component {
  display: inline-flex;
}

.icon-wrapper {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.loginErrorDiv {
  background-color: rgba($color: $errorColor, $alpha: 0.15);
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 20px;
}

#loginErrorMessage {
  color: $errorColor;
  font-size: 12px;
  font-weight: 500;
}

.labelRadioLogin {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.redirectModalContent {
  width: 340px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > h6 {
    color: black;
  }

  > span {
    text-align: center;
    margin: 8px 0px 16px 0px;
    font-weight: 400;
  }
}
