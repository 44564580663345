
.piCard {
	width: 777px;
  background-color: #FFFFFF;
  padding: 40px 24px 24px 40px;
  display: flex;
  justify-content: space-between;
}

.tituloPI {
	height: 31px;
	color: #0C1C2D;
	font-size: 26px;
	font-weight: bold;
  line-height: 31px;
  margin: 12px 24px 12px 0px;
}

.subtituloPI {
	color: #758CA5;
	font-size: 12px;
	line-height: 14px;
}

.cabecalhoPI {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
  }
}

.industrailOrderDetail__body {
  padding-top: 32px;
}
