@import "../../../Utils/Styles/colors.scss";
@import "../../../Utils/Styles/fonts.scss";

.backgroundDashboardAccess {
  background-color: $backgroundSecondaryColor;
  width: 398px;
  height: 434px;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  .dashboardAccessHeader {
    padding: 24px 32px 16px 32px;

    .dashboardAccessTitle {
      display: flex;
      flex-direction: row;

      .dashboardAccessHelpIcon {
        margin-right: 20px;
      }

      .dashboardAccessTitleText {
        margin-right: 8px;
      }
    }

    .dashboardAccessDateParm {
      color: #898c8d;
    }
  }

  .dashboardAccessReportTable {
    display: flex;
    flex-direction: column;

    .dashboardAccessReportLine {
      width: 100%;
      height: 40px;
      padding: 0 32px;
      border-top: 1px solid #f3f4f7;
      align-items: center;
      display: grid;
      grid-template-columns: 35% 65%;

      span {
        @extend .font-small3;
        color: $textSecondaryColor;
        font-weight: bold;
      }

      .dashboardAccessReportPercentage {
        display: flex;
        justify-content: flex-end;

        .percentageBar {
          background-color: $actionPrimaryColor;
          height: 10px;
          border-radius: 2px;
        }

        .percentageBar.bar-background {
          width: 100%;
          background-color: $backgroundPrimaryColor;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .skeletonItem {
      @extend .dashboardAccessReportLine;
      grid-template-columns: 100%;
      .dashboardAccessReportPercentage {
        background-color: $backgroundPrimaryColor;
        border-radius: 2px;
        height: 12px;
      }
    }

    .renderError {
      display: flex;
      justify-content: center;
      height: 320px;
      align-items: center;
      border-top: 1px solid #f3f4f7;
      padding: 0 32px;
    }

    .dashboardAccessReportHeader {
      @extend .dashboardAccessReportLine;
      display: flex;
      justify-content: space-between;
      height: 32px;
    }
  }
}

.dashboardAccessReportToolTip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;

  .regional {
    @extend .font-small1;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
  }
  .access {
    @extend .font-small3;
    color: #ffffff;
    text-align: left;
    margin-top: 4px;
    font-weight: 300;
  }
}
